import React from 'react';

import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {useTitle} from 'shared/hooks/useTitle';
import {useNavbar} from 'shared/hooks/useNavbar';

import {AlertPage} from 'shared/components/AlertPage/AlertPage';

interface INotFoundPageProps {
    children?: React.ReactNode;
}

export const NotFoundPage = ({children}: INotFoundPageProps) => {
    useNavbar(false);
    const {t} = useTranslation();
    useTitle(t('notFoundPage.title', 'Not Found'));

    return (
        <AlertPage
            title={t('notFoundPage.body.title', 'Page not found')}
            description={t('notFoundPage.body.description', 'We could not find the page you\'re looking for')}
        >
            {children ? (
                <>{children}</>
            ) : (
                <Link to="/">{t('notFoundPage.body.returnHomeLink', 'Return Home')}</Link>
            )}
        </AlertPage>
    );
};
