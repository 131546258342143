import {atom, DefaultValue, selector, selectorFamily} from 'recoil';

import {ICourse, ICourseListQuery} from 'modules/course/models';
import {CursorList} from 'shared/models/cursor-list';
import {tenantIdSelector} from 'modules/tenant/state/tenant-info';
import {compareCourseFilters, readCourseList} from 'modules/course/api';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';
import {ILanguage} from 'shared/utils/i18next-config';
import {currentLanguageAtom} from 'shared/state/current-language';

export interface ICourseListFilters {
    filters?: ICourseListQuery;
    page: number;

    [key: string]: number | ICourseListQuery | undefined;
}

interface ICourseListState {
    tenantId: number;
    filters: ICourseListQuery;
    courses: ICourse[];
    hasLoaded: boolean;
    cursors: CursorList;
    page: number;
    more: boolean;
    numberFound: number;
    language: ILanguage;
    _resetVersion: number;

    [key: string]: number | boolean | ICourse[] | CursorList | ICourseListQuery;
}

export const courseListAtom = atom<ICourseListState | undefined>({
    key: 'courseListAtom',
    default: undefined,
});

export const courseListResetAtom = atom<number>({
    key: 'courseListResetAtom',
    default: 0,
});

export const courseListSelector = selectorFamily<ICourseListState, ICourseListFilters>({
    key: 'courseListSelector',
    get: ({page, filters}) => async ({get}): Promise<ICourseListState> => {
        const courseListState = get(courseListAtom);
        const resetVersion = get(courseListResetAtom);
        const tenantId = get(tenantIdSelector);
        const currentLanguage = get(currentLanguageAtom);
        if (
            courseListState &&
            courseListState.tenantId === tenantId &&
            courseListState.hasLoaded &&
            page === courseListState.page &&
            courseListState._resetVersion === resetVersion &&
            JSON.stringify(courseListState.language) === JSON.stringify(currentLanguage) &&
            compareCourseFilters(filters, courseListState.filters)
        ) {
            return {...courseListState};
        }

        // load a fresh page from the server
        const result = await readCourseList(tenantId, currentLanguage, {
            ...filters,
            cursor: page !== 0 && courseListState ? courseListState.cursors[page - 1] : undefined,
        });

        // add the cursor to the list of cursors to allow paginating backwards
        const newCursors = Array.from(courseListState?.cursors ?? []);
        newCursors[page] = result.nextCursor;

        return {
            tenantId,
            filters,
            courses: result.courses,
            cursors: newCursors,
            page,
            more: !!result.nextCursor,
            hasLoaded: true,
            _resetVersion: resetVersion,
            language: currentLanguage,
        } as ICourseListState;
    },
    set: (_) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return null;
        }
        const courseListState = get(courseListAtom);
        if (!courseListState || (
            courseListState.tenantId !== newValue.tenantId ||
            courseListState.courses.length !== newValue.courses.length ||
            courseListState.hasLoaded !== newValue.hasLoaded ||
            courseListState.page !== newValue.page ||
            courseListState.more !== newValue.more ||
            courseListState._resetVersion !== newValue._resetVersion ||
            !compareCourseFilters(newValue.filters, courseListState.filters) ||
            JSON.stringify(courseListState.cursors) !== JSON.stringify(newValue.cursors)
        )) {
            set(courseListAtom, {...newValue});
        }
    },
});

export const courseListReadSelector = selectorFamily<ICourseListState, ICourseListFilters>({
    key: 'courseListReadSelector',
    get: ({page, filters}) => async ({get}): Promise<ICourseListState> => {
        const state = get(courseListSelector({filters, page}));
        if (state) {
            return state;
        }

        const courseListState = get(courseListAtom);
        const tenantId = get(tenantIdSelector);
        const currentLanguage = get(currentLanguageAtom);

        // load a fresh page from the server
        const result = await readCourseList(tenantId, currentLanguage, {
            ...filters,
            cursor: page !== 0 && courseListState ? courseListState.cursors[page - 1] : undefined,
        });

        // add the cursor to the list of cursors to allow paginating backwards
        const newCursors = [...courseListState?.cursors ?? []];
        newCursors[page] = result.nextCursor;

        return {
            tenantId,
            filters,
            courses: result.courses,
            cursors: newCursors,
            page,
            more: !!result.nextCursor,
            hasLoaded: true,
            _resetVersion: get(courseListResetAtom),
            language: currentLanguage,
        } as ICourseListState;
    },
});

export const courseListInsertSelector = selector<ICourse>({
    key: 'courseListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        // if the course is present in the store then update it. Otherwise, force a reload.
        const courseListState = get(courseListAtom);
        if (!courseListState) {
            return;
        }
        const courseIndex = courseListState.courses.findIndex(course => course.id === newValue.id);
        if (courseIndex !== -1) {
            const newCourses = Array.from(courseListState.courses);
            newCourses.splice(courseIndex, 1, newValue);
            set(courseListAtom, {
                ...courseListState,
                courses: newCourses,
            });
        } else {
            set(courseListResetAtom, get(courseListResetAtom) + 1);
        }
    },
});

export const courseListRemoveSelector = selector<number>({
    key: 'courseListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, courseId) => {
        if (courseId instanceof DefaultValue) {
            return;
        }

        // if the course is present in the store then remove it. Otherwise, force a reload.
        const courseListState = get(courseListAtom);
        if (!courseListState) {
            return;
        }
        const courseIndex = courseListState.courses.findIndex(course => course.id === courseId);
        const newCourses = [...courseListState.courses];
        if (courseIndex !== -1) {
            newCourses.splice(courseIndex, 1);
            set(courseListAtom, {
                ...courseListState,
                courses: newCourses,
                numberFound: courseListState.numberFound - 1,
            });
        } else {
            set(courseListResetAtom, get(courseListResetAtom) + 1);
        }
    },
});
