import React from 'react';

import {Route, Routes} from 'react-router';

import {Scope} from 'modules/auth/models';
import {useAuth} from 'shared/hooks/useAuth';
import {RequireGuest} from 'shared/components/auth/RequireGuest/RequireGuest';
import {AsyncComponent} from 'shared/components/AsyncComponent/AsyncComponent';
import {RequireAuth} from 'shared/components/auth/RequireAuth/RequireAuth';
import {NotificationToaster} from 'shared/components/NotificationToaster/NotificationToaster';
import {isIntersection} from 'shared/utils/array';
import {useIsRegistered} from 'modules/marketing-profile/hooks/useIsRegistered';

import {Navbar} from 'shared/components/Navbar/Navbar';
import {Contact} from 'shared/pages/Contact/Contact';
import {MyProfile} from 'shared/pages/MyProfile/MyProfile';
import {TestPage} from 'shared/pages/TestPage/TestPage';
import {Login} from 'auth/pages/Login/Login';
import {Logout} from 'auth/pages/Logout/Logout';
import {ForgotPassword} from 'auth/pages/ForgotPassword/ForgotPassword';
import {ResetPassword} from 'auth/pages/ResetPassword/ResetPassword';
import {ChangeEmailConfirmation} from 'auth/pages/ChangeEmailConfirmation/ChangeEmailConfirmation';
import {Signup} from 'shared/pages/Signup/Signup';
import {UserRegistrationForm} from 'users/pages/UserRegistrationForm/UserRegistrationForm';
import {ViewStaticProducts} from 'shared/pages/ViewStaticProducts/ViewProducts';

const FileManager = React.lazy(() => import('shared/pages/FileManager/FileManager'));
const NotificationsPage = React.lazy(() => import('shared/pages/NotificationsPage/NotificationsPage'));
const LmsApp = React.lazy(() => import('lms/App/App'));
const AdminApp = React.lazy(() => import('admin/App'));
const UserApp = React.lazy(() => import('users/App/App'));
const StudentApp = React.lazy(() => import('student/App'));
const AccountSettingsPage = React.lazy(() => import('shared/pages/AccountSettings/AccountSettings'));

export const App = () => {
    const {tokenData} = useAuth();

    const isRegistered = useIsRegistered(tokenData?.id);

    const isAdmin = isIntersection(tokenData?.scopes, [Scope.TenantEdit, Scope.RoleAdmin]);

    return (
        <>
            <NotificationToaster />
            <Navbar />
            <Routes>
                <Route
                    path="/login"
                    element={(
                        <RequireGuest redirect="/">
                            <Login />
                        </RequireGuest>
                    )}
                />
                <Route
                    path="/join/:signupPageId"
                    element={(
                        <RequireGuest redirect="/">
                            <Signup />
                        </RequireGuest>
                    )}
                />
                <Route
                    path="/forgot"
                    element={(
                        <RequireGuest redirect="/">
                            <ForgotPassword />
                        </RequireGuest>
                    )}
                />
                <Route
                    path="/password/reset/:tokenId/"
                    element={(
                        <RequireGuest redirect="/">
                            <ResetPassword />
                        </RequireGuest>
                    )}
                />
                <Route
                    path="/email/:tokenId/"
                    element={(
                        <RequireAuth>
                            <ChangeEmailConfirmation />
                        </RequireAuth>
                    )}
                />
                <Route
                    path="/logout"
                    element={(
                        <Logout />
                    )}
                />
                <>
                    {(isRegistered) ? (
                        <>
                            <Route
                                path="/products"
                                element={(
                                    <RequireAuth>
                                        <ViewStaticProducts />
                                    </RequireAuth>
                                )}
                            />
                            <Route
                                path="/courses/*"
                                element={(
                                    <RequireAuth>
                                        <AsyncComponent component={LmsApp} />
                                    </RequireAuth>
                                )}
                            />
                            <Route
                                path="/users/*"
                                element={(
                                    <RequireAuth scopes={[Scope.TenantEdit, Scope.AdminCreate, Scope.RoleAdmin]}>
                                        <AsyncComponent component={UserApp} />
                                    </RequireAuth>
                                )}
                            />
                            <Route
                                path="/settings/*"
                                element={(
                                    <RequireAuth scopes={[Scope.SelfEdit]}>
                                        <AsyncComponent component={AccountSettingsPage} />
                                    </RequireAuth>
                                )}
                            />
                            <Route
                                path="/profile/*"
                                element={(
                                    <RequireAuth>
                                        <MyProfile />
                                    </RequireAuth>
                                )}
                            />
                            <Route
                                path="/test"
                                element={(
                                    <RequireAuth>
                                        <TestPage />
                                    </RequireAuth>
                                )}
                            />
                            <Route
                                path="/files/*"
                                element={(
                                    <RequireAuth scopes={[Scope.TenantEdit]}>
                                        <AsyncComponent component={FileManager} />
                                    </RequireAuth>
                                )}
                            />
                            <Route
                                path="/notifications/*"
                                element={(
                                    <RequireAuth scopes={[Scope.SelfEdit]}>
                                        <AsyncComponent component={NotificationsPage} />
                                    </RequireAuth>
                                )}
                            />
                            <Route
                                path="/contact/*"
                                element={(
                                    <Contact />
                                )}
                            />
                            <Route
                                path="*"
                                element={(
                                    <RequireAuth scopes={[Scope.SelfEdit]}>
                                        {isAdmin ? (
                                            <AsyncComponent component={AdminApp} />
                                        ) : (
                                            <AsyncComponent component={StudentApp} />
                                        )}
                                    </RequireAuth>
                                )}
                            />
                        </>
                    ) : (
                        <Route
                            path="*"
                            element={(
                                <RequireAuth>
                                    <UserRegistrationForm />
                                </RequireAuth>
                            )}
                        />
                    )}
                </>
            </Routes>
        </>
    );
};
