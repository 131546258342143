import React from 'react';

import {Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCog, faSignIn, faSignOut} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useAuth} from 'shared/hooks/useAuth';
import {Scope} from 'modules/auth/models';

import {CustomToggle} from 'shared/components/CustomToggle/CustomToggle';

export const NavbarAccountDropdown = () => {
    const {isAuthenticated, tokenData} = useAuth();
    const {t} = useTranslation();

    const isTenantUser = tokenData?.scopes?.indexOf(Scope.TenantEdit) !== -1;

    return (
        <Dropdown id="basic-nav-dropdown" alignRight={true} className="nav-item">
            <Dropdown.Toggle
                as={CustomToggle}
                className="dropdown-toggle nav-link Navbar__dropdown-toggle-icon"
            >
                <FontAwesomeIcon icon={faCog} size="lg" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight={true}>
                <Dropdown.Item as={Link} to="/profile">{t('common.myProfile', 'My Profile')}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to="/settings">{t(
                    'common.accountSettings',
                    'Account Settings',
                )}</Dropdown.Item>
                {(isTenantUser) && (
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Item as={Link} to="/system">{t(
                            'common.systemSettings',
                            'System Settings',
                        )}</Dropdown.Item>
                    </>
                )}
                <Dropdown.Divider />
                {(isAuthenticated) && (
                    <Dropdown.Item as={Link} to="/logout">
                        {t('common.logOut', 'Log Out')}
                        <FontAwesomeIcon icon={faSignOut} size="sm" className="ml-2" />
                    </Dropdown.Item>
                )}
                {(!isAuthenticated) && (
                    <Dropdown.Item as={Link} to="/login">
                        {t('common.logIn', 'Log In')}
                        <FontAwesomeIcon icon={faSignIn} size="sm" className="ml-2" />
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};
