import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import classNames from 'classnames';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

interface ITooltipIconProps {
    id: string;
    placement?: 'top' | 'left' | 'bottom' | 'right';
    size?: 'sm' | 'lg' | '2x' | '3x' | '4x' | '5x';
    className?: string;
    icon: IconProp;
    children?: React.ReactNode;
}

export const TooltipIcon = ({id, children, placement, size, className, icon}: ITooltipIconProps) => {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={(props) => (
                <Tooltip id={id} {...props}>
                    {children}
                </Tooltip>
            )}
            transition={false}
        >
            {({ref, ...triggerHandler}) => (
                <span ref={ref} {...triggerHandler} className={classNames('text-muted', className)}>
                    <FontAwesomeIcon icon={icon} size={size} />
                </span>
            )}
        </OverlayTrigger>
    );
};
