import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';
import {
    assignmentListReadSelector,
    assignmentListSelector,
    IAssignmentListFilters,
} from 'modules/assignment/state/assignment-list';

export const useAssignmentListLoadable = (filters: IAssignmentListFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: assignmentListSelector(filters),
        loadable: assignmentListReadSelector(filters),
    });
    return {
        assignmentList: data,
        loading,
        error,
    };
};
