import {ITenant, ITenantInfo, ITenantInfoSystem} from '../models';

export const tenantToTenantInfo = (tenant: ITenant, tenantInfoSystem: ITenantInfoSystem): ITenantInfo => {
    return {
        id: tenant.id,
        name: tenant.name,
        timezone: tenant.timezone,
        image: tenant.image,
        system: tenantInfoSystem,
    };
};
