import React from 'react';

import { useSignupPageIdFromUrl } from 'modules/signup-page/hooks/useSignupPageIdFromUrl';
import { ISignupPageSummary } from 'modules/signup-page/models';
import { readSignupPageSummary } from 'modules/signup-page/api';

export const useSignupPageSummaryFromUrl = (tenantId: number): ISignupPageSummary | undefined => {
    const [signupPageSummary, setSignupPageSummary] = React.useState<ISignupPageSummary | undefined>();

    const signupPageId = useSignupPageIdFromUrl();
    
    React.useEffect(() => {
        (async () => {
            if (!signupPageSummary) {
                const response = await readSignupPageSummary(tenantId, signupPageId);
                setSignupPageSummary(response);
            }
        })();
    }, [signupPageId, signupPageSummary, tenantId]);

    return signupPageSummary;
};