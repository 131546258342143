import React from 'react';

import {useAuth} from 'shared/hooks/useAuth';

import {NotificationToasterContent} from './NotificationToasterContent';

export const NotificationToaster = () => {
    const {isAuthenticated} = useAuth();

    if (isAuthenticated) {
        return (
            <React.Suspense fallback={null}>
                <NotificationToasterContent />
            </React.Suspense>
        );
    } else {
        return null;
    }
};
