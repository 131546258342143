// breaks an array into chunks. For example:
// >>> chunk([1, 2, 3, 4, 5], 3)
// [[1, 2, 3], [4, 5]]
export function chunk<T>(arr: T[], size: number): T[][] {
    return [...Array(Math.ceil(arr.length / size))].map((_, i) =>
        arr.slice(size * i, size + size * i),
    );
}

export const getIntersection = <T>(a1: T[], a2: T[]): T[] => {
    return a1.filter(value => a2.includes(value));
};

export const isIntersection = <T>(a1?: T[], a2?: T[]): boolean => {
    if (!a1 || !a2) return false;
    for (const value of a1) {
        if (a2.includes(value)) return true;
    }
    return false;
};