import React from 'react';

import {Button, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCertificate, faDollarSign} from '@fortawesome/free-solid-svg-icons';

import {showComingSoonToast} from 'shared/utils/coming-soon-toast';
import {IProduct} from 'modules/product/models';

import './style.scss';

export interface IProductCardProps {
    product: IProduct;
}

export const ProductCard: React.FC<IProductCardProps> = ({product}) => {
    const handleClick = () => {
        const url = `https://buy.stripe.com/${product.id}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const ProductDescription: React.FC<{description: string}> = ({description}) => (
        <div style={{whiteSpace: 'pre-line'}}>{description}</div>
    );

    return (
        <Card style={{width: '100%'}}>
            <Card.Header className="text-center" style={{position: 'relative', padding: 'none'}}>
                <Card.Title>{product.name}</Card.Title>
                <div className="position-relative">
                    <Card.Img src={product.bannerImg} />
                    <div className="certificate-container position-absolute">
                        <FontAwesomeIcon size="4x" icon={faCertificate} color="red" />
                        <span className="price-text"><FontAwesomeIcon size="sm" icon={faDollarSign} />{product.price}</span>
                    </div>
                </div>
                {product.subname && (
                    <Card.Title style={{fontSize: '0.8rem', fontWeight: 'bold', paddingTop: '8px'}} className="right">
                        {product.subname}
                    </Card.Title>
                )}
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    <ProductDescription description={product.description} />
                </Card.Text>
                <div className="pt-2">
                    <Card.Title className="pl-4" style={{fontSize: '0.8rem', fontWeight: 'bold'}}>
                        Course Coverage:
                    </Card.Title>
                    <ol>
                        {product.coverage.map((module, index) => (
                            <li key={index}>{module}</li>
                        ))}
                    </ol>
                </div>
            </Card.Body>
            <Card.Footer>
                <div className="d-flex">
                    <Button variant="success" onClick={handleClick}>
                        Buy
                    </Button>
                    <Button className="ml-auto" onClick={showComingSoonToast}>
                        View Course
                    </Button>
                </div>
            </Card.Footer>
        </Card>
    );
};
