import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';
import {assignmentProgressReadSelector, assignmentProgressSelector, IAssignmentProgressKey, IAssignmentProgressState} from '../state';
import {IAssignmentProgress} from 'modules/assignment-progress/models';

export const useAssignmentProgressLoadable = (key: IAssignmentProgressKey) => {
    const {data, loading, error} = useRecoilLoadable({
        state: assignmentProgressSelector(key),
        loadable: assignmentProgressReadSelector(key),
    });
    let state: IAssignmentProgress | undefined;

    if (data) {
        const {assignmentProgress} = data as IAssignmentProgressState;
        state = assignmentProgress;
    }

    return {
        assignmentProgress: state,
        loading,
        error,
    };
};
