import React from 'react';

import './style.scss';

export const ProgressSliderLoading = () => {
    return (
        <div className="ProgressSliderLoading">
            <div className="ProgressSliderLoading__point ProgressSliderLoading__point--a" />
            <div className="ProgressSliderLoading__point ProgressSliderLoading__point--b" />
            <div className="ProgressSliderLoading__point ProgressSliderLoading__point--c" />
        </div>
    );
};
