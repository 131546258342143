import {Container, Row, Col} from 'react-bootstrap';

import {useTitle} from 'shared/hooks/useTitle';
import {useGuaranteedAuth} from 'shared/hooks/useAuth';
import {useNavbar} from 'shared/hooks/useNavbar';
import {IProduct} from 'modules/product/models';

import {ProductCard} from './ProductCard';

const FutureFoundersProduct: IProduct = {
    tenant_id: 0,
    id: 'cN2aGh6d62sd1fabII',
    name: 'Future Founders',
    subname: 'Kickstarter Course',
    description: 'Passionate about business?\n\nThen this is the course for you! This online course is specifically created for youth with an entrepreneurial mind aged 13-17 years. This 12 module startup course is jam-packed with self-paced coursework, activities, questionaires and more.',
    coverage: [
        'Entrepreneurial Mindset',
        'Business Model Canvas',
        'Problems & Solutions',
        'Market Research',
        'SWOT Analysis',
        'Prototyping',
        'Financial Foundations',
        'Managing Risks',
        'Building Your Brand',
        'Marketing Strategies',
        'Website Development Overview',
        'Refinement, Feedback & SMART Goals',
    ],
    bannerImg: 'https://api.staging.cloudschool.org/services/file/tenants/6314810471874560/files/5715568111910912/download/StartGap-banner.jpeg',
    price: 95,
    created_at: '12/07/24',
};

const ProgrammingProdigiesProduct: IProduct = {
    tenant_id: 0,
    id: '7sI15HgRKd6R1fa145',
    name: 'Programming Prodigies',
    subname: 'Kickstarter Course',
    description: 'Want to build your own website?\n\nLook no further! This online course is catered to technology-interested youth 13-17 years. This 8 module Javascript-focused course is loaded with self-paced coursework, exercises, questionaires and more.',
    coverage: [
        'Introduction to JavaScript',
        'Variables and Data Types',
        'Control Structures',
        'Functions',
        'Arrays and Objects',
        'DOM Manipulation',
        'Introduction to Asynchronous Programming',
        'Practical Application and Project',
    ],
    bannerImg: 'https://api.staging.cloudschool.org/services/file/tenants/6314810471874560/files/5735934108631040/download/javascript.png',
    price: 95,
    created_at: '12/07/24',
};

export const ViewStaticProducts = () => {
    useTitle('View Products');
    useNavbar(true);

    const {tenantId} = useGuaranteedAuth();

    FutureFoundersProduct.tenant_id = tenantId;
    ProgrammingProdigiesProduct.tenant_id = tenantId;

    return (
        <Container fluid>
            <h1 className="text-center my-4">View Our Products</h1>
            <Row className="justify-content-center">
                <Col xs={12} md={6} lg={4} className="d-flex justify-content-center mb-4">
                    <ProductCard product={FutureFoundersProduct} />
                </Col>
                <Col xs={12} md={6} lg={4} className="d-flex justify-content-center mb-4">
                    <ProductCard product={ProgrammingProdigiesProduct} />
                </Col>
            </Row>
        </Container>
    );
};