import React from 'react';

import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

interface IFullScreenModalCloseProps {
    to?: string;
    onClick?: () => void;
}

export const FullScreenModalClose = ({to, onClick}: IFullScreenModalCloseProps) => {
    return (
        <Link className="FullScreenModal__close-link p-2" to={(onClick && !to) ? '' : (to ? to : '/')} tabIndex={1} onClick={onClick}>
            <FontAwesomeIcon icon={faTimes} className="FullScreenModal__close-icon" />
        </Link>
    );
};
