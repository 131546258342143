import {atomFamily, selectorFamily} from 'recoil';

import {tenantIdSelector} from '../../tenant/state/tenant-info';
import {readAssignmentCount} from '../api';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';

interface IAssignmentCountState {
    count: number,
    _resetVersion: number,
}

export const assignmentCountAtom = atomFamily<IAssignmentCountState | undefined, number>({
    key: 'assignmentCountAtom',
    default: undefined,
});

export const assignmentCountResetAtom = atomFamily<number, number>({
    key: 'assignmentCountResetAtom',
    default: 0,
});

export const assignmentCountSelector = selectorFamily<number | undefined, number>({
    key: 'assignmentCountSelector',
    get: (courseId) => ({get}) => {
        const count = get(assignmentCountAtom(courseId));
        return count?.count;
    },
    set: (courseId) => ({set, get}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(
            assignmentCountAtom(courseId),
            {count: newValue, _resetVersion: get(assignmentCountResetAtom(courseId))},
        );
    },
});

export const assignmentCountReadSelector = selectorFamily<number, number>({
    key: 'assignmentCountReadSelector',
    get: (courseId) => async ({get}): Promise<number> => {
        const tenantId = get(tenantIdSelector);
        const valueInAtom = get(assignmentCountSelector(courseId));
        if (valueInAtom) {
            return valueInAtom;
        }
        const result = await readAssignmentCount({
            tenantId,
            courseId,
        });
        return result.assignment_count;
    },
});