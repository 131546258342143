import React from 'react';

import {Container} from 'react-bootstrap';

import {TestPageGrays} from './TestPageGrays';
import './style.scss';

export const TestPage = () => {
    return (
        <Container className="py-5">
            <h1 className="mb-5">Test</h1>
            <TestPageGrays />
        </Container>
    );
};
