import React from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import i18next from 'i18next';

import { userLookupReadMaybeSelector } from 'modules/user/state/user-lookup';
import { useAuth } from 'shared/hooks/useAuth';
import { useLoadingPage } from 'shared/hooks/useLoadingPage';
import { currentLanguageAtom } from 'shared/state/current-language';
import { getLanguageFromCode } from 'shared/utils/current-language';

interface ILanguageProviderProps {
    children: React.ReactNode;
}

export const LanguageSetter = ({children}: ILanguageProviderProps) => {
    const {tokenData, isLoading} = useAuth();

    const user = useRecoilValue(userLookupReadMaybeSelector(isLoading ? undefined : tokenData?.id));

    const loading = !!tokenData && !user && !isLoading;

    useLoadingPage(loading);

    const setLanguage = useSetRecoilState(currentLanguageAtom);

    React.useEffect(() => {
        if (user && user.language) {
            i18next.changeLanguage(user.language);
            setLanguage(getLanguageFromCode(user.language));
        }
    }, [setLanguage, user, user?.language]);

    return (
        <>{!loading && children}</>
    );
};