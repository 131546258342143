import React from 'react';

import {Form, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';

import {Gender, IUpdateMarketingProfile} from 'modules/marketing-profile/models/marketing-profile';

import {FormError} from 'shared/components/forms/FormError/FormError';

const userRegistrationFormSchema = Yup.object().shape({
    DOB: Yup.date().required(),
    school: Yup.string().required().min(1),
    year: Yup.number().required().min(1),
    phone: Yup.string().optional(),
    gender: Yup.string().oneOf(Object.values(Gender)).required(),
});

interface IUserRegistrationInfoProps {
    isSubmitting: boolean;
    formData?: IUpdateMarketingProfile;
    onSetData: (data: IUpdateMarketingProfile) => void;
    onNextStep: () => void;
}

export const UserRegistrationInfo = ({isSubmitting, formData, onSetData, onNextStep}: IUserRegistrationInfoProps) => {
    const {t} = useTranslation();

    const {handleSubmit, formState: {errors}, register} = useForm<IUpdateMarketingProfile>({
        resolver: yupResolver(userRegistrationFormSchema),
        defaultValues: formData,
    });

    const onSubmit = (newFormData: IUpdateMarketingProfile) => {
        if (!isSubmitting) {
            onSetData(newFormData);
            onNextStep();
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="DOB" className="mb-3">
                <Form.Label className="h3">Date of birth:</Form.Label>
                <Form.Control
                    className="form-control-lg"
                    type="date"
                    placeholder="Enter your DOB..."
                    {...register('DOB')}
                />
                <FormError name="DOB" errors={errors}/>
            </Form.Group>
            <Form.Group controlId="school" className="mb-3">
                <Form.Label className="h3">School:</Form.Label>
                <Form.Control
                    className="form-control-lg"
                    type="text"
                    placeholder="Enter the name of your school..."
                    {...register('school')}
                />
                <FormError name='school' errors={errors}/>
            </Form.Group>
            <Form.Group controlId="year" className="mb-3">
                <Form.Label className="h3">{t('userRegistrationInfo.form.year', 'Year at school:')}</Form.Label>
                <Form.Control
                    className="form-control-lg"
                    type="number"
                    placeholder="Enter your year at school..."
                    {...register('year')}
                />
                <FormError name="year" errors={errors}/>
            </Form.Group>
            <Form.Group controlId="phone" className="mb-3">
                <Form.Label className="h3">{t('userRegistrationInfo.form.phone', 'Phone: (Optional)')}</Form.Label>
                <Form.Control
                    className="form-control-lg"
                    placeholder="Enter your Phone number..."
                    type="text"
                    {...register('phone')}
                />
                <FormError name="phone" errors={errors}/>
            </Form.Group>
            <Form.Group controlId="gender" className="mb-3">
                <Form.Label className="h3 mb-1">{t('userRegistrationInfo.form.gender', 'Gender:')}</Form.Label>
                <Form.Check
                    id="gender-male"
                    className="form-control-sm align-bottom ml-2"
                    type="radio" 
                    label={t(
                        'userRegistrationInfo.form.gender.male',
                        'Male',
                    )}
                    style={{fontSize: '1rem'}}
                    value={Gender.MALE}
                    {...register('gender')}
                />
                <Form.Check
                    id="gender-female"
                    className="form-control-sm align-bottom ml-2"
                    type="radio" 
                    label={t(
                        'userRegistrationInfo.form.gender.female',
                        'Female',
                    )}
                    style={{fontSize: '1rem'}}
                    value={Gender.FEMALE}
                    {...register('gender')}
                />
                <Form.Check
                    id="gender-other"
                    className="form-control-sm align-bottom ml-2"
                    type="radio" 
                    label={t(
                        'userRegistrationInfo.form.gender.other',
                        'Other',
                    )}
                    style={{fontSize: '1rem'}}
                    value={Gender.OTHER}
                    {...register('gender')}
                />
                <FormError name="gender" errors={errors}/>
            </Form.Group>
            <Button
                variant="primary"
                type="submit"
                style={{marginLeft: 'auto', fontSize: 'larger', float: 'right'}}
                disabled={isSubmitting}
            >
                {t('userRegistrationInfo.form.next', 'Next Step')}
            </Button>
        </Form>
    );
};