import React from 'react';

interface IAsyncComponentProps {
    component: React.LazyExoticComponent<() => JSX.Element>;
}

export const AsyncComponent = ({component}: IAsyncComponentProps) => {
    const Component = component;
    return (
        <Component />
    );
};
