import React from 'react';

import {useRecoilStateLoadable} from 'recoil';

import {unreadNotificationCountSelector} from '../state/unread-notification-count';

interface IUseUnreadNotificationCountProps {
    tenantId: number;
    userId: number;
}

export const useUnreadNotificationCount = ({
    tenantId,
    userId,
}: IUseUnreadNotificationCountProps): number | undefined => {
    // load unread notification count in a "loadable" so that suspense or errors aren't shown. We'll just show the
    // badge when we have it.
    const [unreadCountLoadable, setUnreadCount] = useRecoilStateLoadable(unreadNotificationCountSelector({
        tenantId,
        userId,
    }));
    const unreadCount = unreadCountLoadable.state === 'hasValue' ? unreadCountLoadable.contents : undefined;

    // set the recoil state value after loading
    React.useEffect(() => {
        if (unreadCountLoadable.state === 'hasValue') {
            setUnreadCount(unreadCountLoadable.contents);
        }
    }, [setUnreadCount, unreadCountLoadable]);

    return unreadCount;
};
