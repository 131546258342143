import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';
import {notificationListSelector, INotificationListFilters, notificationListReadSelector} from '../state/notification-list';
import {INotificationListState} from '../state/notification-list';

export const useNotificationsLoadable = (filters: INotificationListFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: notificationListSelector(filters),
        loadable: notificationListReadSelector(filters),
    });

    return {
        notificationList: data,
        loading,
        error,
    };
};