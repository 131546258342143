import React from 'react';

import {Navbar as BootstrapNavbar} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {TenantProfilePicture} from 'shared/components/TenantProfilePicture/TenantProfilePicture';
import './style.scss';

export const NavbarProfilePicture = () => {
    return (
        <BootstrapNavbar.Brand as={Link} to="/" className="Navbar__brand ml-1">
            <TenantProfilePicture round className="Navbar__picture" />
        </BootstrapNavbar.Brand>
    );
};
