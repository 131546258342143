import {FieldErrors, FieldValues, Path} from 'react-hook-form';
import {Form} from 'react-bootstrap';

interface IFormErrorProps<FormType extends FieldValues> {
    name: Path<FormType>;
    errors: FieldErrors<FormType>;
}

export const FormError = <FormType extends FieldValues>({errors, name}: IFormErrorProps<FormType>) => {
    return (
        <>
            {!!errors[name] && (
                <Form.Control.Feedback type="invalid" className="d-block mt-1">
                    {errors[name].message}
                </Form.Control.Feedback>
            )}
        </>
    );
};
