import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';

export interface ILanguage {
    readonly name: string;
    readonly code: string;

    [key: string]: string;
}

export const DEFAULT_LANGUAGE: ILanguage = {name: 'English', code: 'en'}; 

export const PlatformLanguages: ILanguage[] = [
    DEFAULT_LANGUAGE,
    {name: 'Khmer', code: 'km'},
];

const detectedLangages = [...window.navigator.languages];
let defaultLanguage = (process.env.NODE_ENV === 'development') ? 'dev' : 'en';

for (const language of detectedLangages) {
    for (const pLang of PlatformLanguages) {
        if (language === pLang.code) {
            defaultLanguage = language;
            break;
        }
    }
}

i18next
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        lng: defaultLanguage,
        fallbackLng: 'en',
        defaultNS: 'common',
        ns: ['common'],
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
        },
        supportedLngs: PlatformLanguages.map(language => language.code),
    });
