import React, { useState } from 'react';

import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';

import { useTitle } from 'shared/hooks/useTitle';
import { getAxiosErrorOrThrow } from 'shared/utils/error';
import { useTenantInfo } from 'modules/tenant/hooks';
import { resetPassword } from '../../../modules/user/api/password';

import { Spinner } from 'shared/components/Spinner/Spinner';
import { FullScreenModal } from 'shared/components/FullScreenModal/FullScreenModal';
import { FullScreenModalTitle } from 'shared/components/FullScreenModal/FullScreenModalTitle';

import './style.scss';

type ForgotPasswordFormData = {
    username: string;
    password: string;
};

export const ForgotPassword = () => {
    const {t} = useTranslation();
    const {register, handleSubmit} = useForm<ForgotPasswordFormData>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const navigate = useNavigate();
    const {tenantInfo} = useTenantInfo();

    useTitle(t('forgotPassword.browserTitle', 'Forgot Password'));

    const onSubmit = handleSubmit(({username, password}) => {
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        (async () => {
            try {
                const resetPasswordResponse = await resetPassword({
                    tenantId: tenantInfo.id,
                    username,
                });
                toast.info(resetPasswordResponse.message);
                navigate('/login');
            } catch (error) {
                toast.error(getAxiosErrorOrThrow(error));
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <FullScreenModal closeTo="/login" size="sm">
            <FullScreenModalTitle>{t('forgotPassword.pageTitle', 'Forgotten your Password?')}</FullScreenModalTitle>
            <Form onSubmit={onSubmit}>
                <Form.Group controlId="ForgotPassword__username" className="FullScreenModal__form-group">
                    <Form.Text>
                        <Trans i18nKey="forgotPassword.description">
                            Enter your email address and we’ll send you instructions on how to reset it.
                        </Trans>
                    </Form.Text>
                    <Form.Control
                        className="FullScreenModal__form-control"
                        type="text"
                        placeholder={t('forgotPassword.placeholder', 'Email Address')}
                        disabled={isSubmitting}
                        autoFocus
                        {...register('username')}
                    />
                </Form.Group>
                <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    className="FullScreenModal__button"
                    block
                >
                    {isSubmitting ? (
                        <>
                            {t('forgotPassword.submitButton', 'Sending the instructions...', {context: 'busy'})}
                            <Spinner size="sm"/>
                        </>
                    ) : (
                        t('forgotPassword.submitButton', 'Send me the instructions!')
                    )}
                </Button>
            </Form>
        </FullScreenModal>
    );
};
