import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle, faRedo} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';

interface ILoadingErrorProps {
    children?: React.ReactNode;
    reload?: () => void;
}

export const LoadingError = ({children, reload}: ILoadingErrorProps) => {
    return (
        <div className="py-3 text-center">
            <div className="mb-3">
                <FontAwesomeIcon icon={faExclamationTriangle} size="3x" className="text-danger" />
            </div>
            <div className="text-muted lead">
                {children}
            </div>
            {reload && (
                <div className="mt-3">
                    <Link to="#" onClick={reload}>
                        <FontAwesomeIcon icon={faRedo} className="me-1" size="lg" /> Retry
                    </Link>
                </div>
            )}
        </div>
    );
};
