import React from 'react';

import {Dropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {INotification} from 'modules/notification/models';

import {NavbarNotificationsMarkAsRead} from './NavbarNotificationsMarkAsRead';

import './style.scss';

interface INavbarNotificationsHeaderProps {
    notifications: INotification[] | undefined;
}

export const NavbarNotificationsHeader = ({notifications}: INavbarNotificationsHeaderProps) => {
    const {t} = useTranslation();

    return (
        <Dropdown.ItemText className="NavbarNotifications__dropdown-header">
            <div className="d-flex align-items-center">
                <span>{t('navbarNotifications.notifications', 'Notifications')}</span>
                <span className="flex-grow-1 text-right">
                    {!!notifications?.length && (
                        <NavbarNotificationsMarkAsRead />
                    )}
                    <Link to="/notifications">View All</Link>
                </span>
            </div>
        </Dropdown.ItemText>
    );
};
