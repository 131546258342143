import React from 'react';

import {format} from 'date-fns';
import {zonedTimeToUtc} from 'date-fns-tz';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {toast} from 'react-toastify';

import {IRecord, RecordStatus} from 'modules/record/models';
import {createRecordDownloadUrl} from 'modules/record/api';
import {getAxiosErrorOrThrow} from 'shared/utils/error';
import { showComingSoonToast } from 'shared/utils/coming-soon-toast';

import {ICertificateDownload} from 'modules/course-certificate/models';

import './styles.scss';

interface ICourseRecordTableRowProps {
    record: IRecord;
}

export const CourseRecordTableRow = ({record}: ICourseRecordTableRowProps) => {
    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const viewCourseUrl = `/courses/${record.course_id}`;
    const viewSnapshotUrl = `snapshots/${record.course_id}/${record.user_id}`;

    const utc = zonedTimeToUtc(record.created_at, 'UTC');
    const formattedDate = format(utc, 'PPPp');

    const classNameComputed = classNames({
        'CourseRecordTable__status--pass': record.status === RecordStatus.PASS,
        'CourseRecordTable__status--fail': record.status === RecordStatus.FAIL,
        'CourseRecordTable__status--pending': record.status === RecordStatus.PENDING,
    });

    // TODO: finish designing the course certificate before implementing
    const downloadCertificate = () => {
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        (async () => {
            try {
                const result: ICertificateDownload = await createRecordDownloadUrl(
                    record.tenant_id,
                    record.course_id,
                    record.user_id,
                    record.stint_version,
                );
                const link = document.createElement('a');
                link.href = result.download_url;
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                toast.error(getAxiosErrorOrThrow(error));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <tr>
            <td>
                <Link to={viewCourseUrl}>
                    {record.metadata.course_name}
                </Link>
            </td>
            <td>{formattedDate}</td>
            <td className={classNameComputed}>
                {record.status}
            </td>
            <td className='CourseRecordTable__download'>
                <Link className='text-center' to={viewSnapshotUrl}>
                    {t('common.view', 'View')}
                </Link>
            </td>
            <td className='CourseRecordTable__download'>
                <Link className='text-center' to="#" onClick={showComingSoonToast}>
                    {isSubmitting 
                        ? t('common.download', 'Downloading...', {context: 'busy'})
                        : t('common.download', 'Download') 
                    }
                </Link>
            </td>
        </tr>
    );
};