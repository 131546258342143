import axios from 'axios';

import {getApiUrlForPath, getAxiosHeaders, getAxiosOptions} from 'shared/utils/api';
import {getAccessTokenSilently} from 'shared/utils/token';
import {IRecord, IRecordCreate, IRecordListQuery, IRecordListResult} from '../models';
import {ICertificateDownload} from 'modules/course-certificate/models';

const getRecordUrl = (tenantId: number, path: string) => {
    return getApiUrlForPath(`/tenants/${tenantId}/records${path}`);
};

export const readRecordList = async (tenantId: number, query: IRecordListQuery): Promise<IRecordListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getRecordUrl(tenantId, '/'), {
        params: query,
        headers: getAxiosHeaders(accessToken),
    });
    return response.data;
};

export const readRecord = async (tenantId: number, courseId: number, userId: number, stintVersion: number): Promise<IRecord> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getRecordUrl(tenantId, `/${courseId}/${userId}/${stintVersion}`),
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const createRecord = async (tenantId: number, recordCreate: IRecordCreate): Promise<IRecord> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getRecordUrl(tenantId, '/'),
        recordCreate,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const createRecordDownloadUrl = async (
    tenantId: number,
    courseId: number,
    userId: number,
    stintVersion: number,
): Promise<ICertificateDownload> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getRecordUrl(tenantId, `/${courseId}/${userId}/${stintVersion}/download`),
        getAxiosOptions(accessToken),
    );
    return response.data;
};