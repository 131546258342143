import {atom, selector, selectorFamily} from 'recoil';

import {IRecord, IRecordKey, IRecordListQuery} from '../models';
import {readRecordList} from '../api';
import {tenantIdSelector} from 'modules/tenant/state/tenant-info';
import {CursorList} from 'shared/models/cursor-list';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';

export interface IRecordListFilters {
    filters: IRecordListQuery;
    page: number;

    [key: string]: string | number | IRecordListQuery | undefined; 
}

interface IRecordListState {
    records: IRecord[];
    filters: IRecordListQuery;
    page: number;
    cursors: CursorList;
    more: boolean;
}

export const recordListAtom = atom<IRecordListState | undefined>({
    key: 'recordListAtom',
    default: undefined,
});

export const recordListSelector = selectorFamily<IRecordListState | undefined, IRecordListFilters>({
    key: 'recordListSelector',
    get: ({page, filters}) => ({get}) => {
        const state = get(recordListAtom);
        if (
            state &&
            state.page === page &&
            JSON.stringify(state.filters) === JSON.stringify(filters)
        ) {
            return state;
        }
        return undefined;
    },
    set: (_) => ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(recordListAtom, newValue);
    },
});

export const recordListReadSelector = selectorFamily<IRecordListState, IRecordListFilters>({
    key: 'recordListReadSelector',
    get: ({filters, page}) => async ({get}) => {
        const state = get(recordListSelector({filters, page}));
        if (state) {
            return state;
        }
        const tenantId = get(tenantIdSelector);
        const atom = get(recordListAtom);
        const response = await readRecordList(tenantId, {
            ...filters,
            cursor: (page === 0 || !atom) ? undefined : atom.cursors[page - 1],
        });
        
        const newCursors = atom ? atom.cursors : [];
        newCursors[page] = response.next_cursor;
        return {
            records: response.records,
            filters: filters,
            page,
            cursors: newCursors,
            more: response.more,
        } as IRecordListState;
    },
});

export const recordListInsertSelector = selector<IRecord>({
    key: 'recordListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const state = get(recordListAtom);
        if (!state) {
            return;
        }
        const index = state.records.findIndex(record => {
            return (
                record.course_id === newValue.course_id && 
                record.user_id === newValue.user_id && 
                record.stint_version === newValue.stint_version
            );
        });
        if (index === -1) {
            set(recordListAtom, {
                ...state, 
                records: [...state?.records ?? [], newValue],
            } as IRecordListState);
        }
        const newState = {...state};
        newState.records = newState.records.splice(index, 1, newValue);
        set(recordListAtom, newState);

    },
});

export const recordListRemoveSelector = selector<IRecordKey>({
    key: 'recordListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, key) => {
        if (guardRecoilDefaultValue(key)) {
            return;
        }
        const state = get(recordListAtom);
        if (!state) {
            return;
        }
        const index = state.records.findIndex(record => {
            return (
                record.course_id === key.courseId && 
                record.user_id === key.userId && 
                record.stint_version === key.stintVersion
            );
        });
        if (index === -1) {
            return;
        }
        const newState = {...state};
        newState.records = newState.records.splice(index, 1);
        set(recordListAtom, newState);
    },
});