import {atom, DefaultValue, selector, selectorFamily} from 'recoil';

import {INotification, INotificationListQuery} from 'modules/notification/models';
import {CursorList} from 'shared/models/cursor-list';
import {compareNotificationFilters, readNotifications} from 'modules/notification/api';
import {unreadNotificationCountAtom} from 'modules/notification/state/unread-notification-count';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';

export interface INotificationListFilters {
    filters: INotificationListQuery;
    page: number;
    tenantId: number;

    [key: string]: number | INotificationListQuery | undefined;
}

export interface INotificationListState {
    tenantId: number;
    filters: INotificationListQuery;
    notifications: INotification[];
    hasLoaded: boolean;
    cursors: CursorList;
    page: number;
    more: boolean;
    numberFound: number;
    _resetVersion: number;

    [key: string]: number | boolean | INotification[] | CursorList | INotificationListQuery;
}

export const notificationListAtom = atom<INotificationListState>({
    key: 'notificationListAtom',
    default: {
        tenantId: 0,
        filters: {
            userId: 0,
        },
        notifications: [],
        hasLoaded: false,
        cursors: [],
        page: 0,
        more: false,
        numberFound: 0,
        _resetVersion: 0,
    },
});

export const notificationListResetAtom = atom<number>({
    key: 'notificationListResetAtom',
    default: 0,
});

export const notificationListSelector = selectorFamily<INotificationListState | undefined, INotificationListFilters>({
    key: 'notificationListSelector',
    get: ({tenantId, page, filters}) => ({get}) => {
        const notificationListState = get(notificationListAtom);
        const resetVersion = get(notificationListResetAtom);

        if (
            notificationListState &&
            notificationListState.hasLoaded &&
            page === notificationListState.page &&
            tenantId === notificationListState.tenantId &&
            notificationListState._resetVersion === resetVersion &&
            compareNotificationFilters(filters, notificationListState.filters)
        ) {
            return {...notificationListState};
        }

        return undefined;
    },
    set: (_) => ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(notificationListAtom, newValue);
    },
});

export const notificationListReadSelector = selectorFamily<INotificationListState, INotificationListFilters>({
    key: 'notificationListReadSelector',
    get: ({tenantId, page, filters}) => async ({get}): Promise<INotificationListState> => {
        const notificationListState = get(notificationListSelector({tenantId, page, filters}));
        if (notificationListState) {
            return notificationListState;
        }

        const atomState = get(notificationListAtom);
        const resetVersion = get(notificationListResetAtom);

        // load a fresh page from the server
        const result = await readNotifications(tenantId, {
            ...(filters || {}),
            cursor: page === 0 || !atomState ? undefined : atomState.cursors[page - 1],
        });

        // add the cursor to the list of cursors so we can paginate backwards
        const newCursors = atomState ? [...atomState.cursors] : [];
        newCursors[page] = result.nextCursor;

        return {
            tenantId,
            filters,
            notifications: result.notifications,
            cursors: newCursors,
            page,
            more: !!result.nextCursor,
            hasLoaded: true,
            _resetVersion: resetVersion,
        } as INotificationListState;
    },
});

export const notificationListInsertSelector = selector<INotification>({
    key: 'notificationListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        // if the notification is present in the store then update it. Otherwise force a reload.
        const notificationListState = get(notificationListAtom);
        const notificationIndex = notificationListState.notifications.findIndex(notification => notification.id === newValue.id);
        if (notificationIndex !== -1) {
            const newNotifications = Array.from(notificationListState.notifications);
            newNotifications.splice(notificationIndex, 1, newValue);
            set(notificationListAtom, {
                ...notificationListState,
                notifications: newNotifications,
            });
        } else {
            set(notificationListResetAtom, get(notificationListResetAtom) + 1);
        }
    },
});

export const notificationListRemoveSelector = selector<number>({
    key: 'notificationListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, notificationId) => {
        if (notificationId instanceof DefaultValue) {
            return;
        }

        // if the notification is present in the store then remove it. Otherwise force a reload.
        const notificationListState = get(notificationListAtom);
        const notificationIndex = notificationListState.notifications.findIndex(notification => notification.id === notificationId);
        if (notificationIndex !== -1) {
            set(notificationListAtom, {
                ...notificationListState,
                notifications: notificationListState.notifications.filter(notification => notification.id !== notificationId),
                numberFound: notificationListState.numberFound - 1,
            });
        } else {
            set(notificationListResetAtom, get(notificationListResetAtom) + 1);
        }
    },
});

export const notificationInNotificationListSelector = selectorFamily<INotification | undefined, number>({
    key: 'notificationInNotificationListSelector',
    get: (notificationId) => ({get}): INotification | undefined => {
        const notificationListState = get(notificationListAtom);
        return notificationListState.notifications.find(notification => notification.id === notificationId);
    },
});

export const markAllNotificationsAsReadSelector = selector<true>({
    key: 'markAllNotificationsAsReadSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const notificationState = get(notificationListAtom);
        const unreadCountState = get(unreadNotificationCountAtom);
        if (notificationState) {
            const notifications = notificationState.notifications.map(notification => ({
                ...notification,
                is_read: true,
                is_seen: true,
            }));
            set(notificationListAtom, {
                ...notificationState,
                notifications,
            });
            set(unreadNotificationCountAtom, {
                ...unreadCountState,
                unreadCount: 0,
            });
        }
    },
});
