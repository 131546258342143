import React from 'react';

import {Badge, Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {useRecoilValue} from 'recoil';

import {useGuaranteedAuth} from 'shared/hooks/useAuth';
import {userLookupReadSelector} from 'modules/user/state/user-lookup';
import {CustomToggle} from 'shared/components/CustomToggle/CustomToggle';
import {useUnreadNotificationCount} from 'modules/notification/hooks/useUnreadNotificationCount';

import {NavbarNotificationsHeader} from './NavbarNotificationsHeader';
import {NavbarNotificationsItem} from './NavbarNotificationsItem';

import './style.scss';
import {useNotificationsLoadable} from 'modules/notification/hooks/useNotificationsLoadable';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';

export const NavbarNotifications = () => {
    const {t} = useTranslation();
    const {tenantId, tokenData} = useGuaranteedAuth();
    const unreadCount = useUnreadNotificationCount({
        tenantId,
        userId: tokenData.id,
    });
    const user = useRecoilValue(userLookupReadSelector(tokenData.id));

    const {notificationList, loading, error} = useNotificationsLoadable({
        tenantId: tenantId,
        filters: {
            userId: tokenData.id,
        },
        page: 0,
    });

    const [show, setShow] = React.useState(false);

    const unReadNotifications = notificationList?.notifications.filter(notification => !notification.is_read);

    return (
        <Dropdown
            id="basic-nav-dropdown"
            alignRight={true}
            className="nav-item"
            show={show}
            onToggle={(isOpen, event, metadata) => {
                if (metadata.source !== 'select') {
                    setShow(isOpen);
                }
            }}
        >
            <Dropdown.Toggle
                as={CustomToggle}
                className="dropdown-toggle nav-link Navbar__dropdown-toggle-icon"
            >
                <FontAwesomeIcon icon={faBell} size="lg" />
                {!!unreadCount && (
                    <Badge className="Navbar__nav-item-badge" variant="danger">{unreadCount}</Badge>
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight={true} style={{width: '22rem'}} className="NavbarNotifications__dropdown-menu">
                <NavbarNotificationsHeader notifications={unReadNotifications} />
                <Dropdown.Divider className="NavbarNotifications__dropdown-divider" />
                {(notificationList && !notificationList?.notifications.length) && (
                    <Dropdown.Item disabled className="NavbarNotifications__dropdown-item-none">
                        <div className="d-flex align-items-center">
                            <span>{t('navbarNotifications.empty', 'There are no new notifications.')}</span>
                        </div>
                    </Dropdown.Item>
                )}
                {error && (
                    <span>{t('navbarNotifications.error', 'There was an error loading the notifications list')}</span>
                )}
                {loading && (
                    <LoadingSpinner />
                )}
                {notificationList && notificationList.notifications.map(notification => (
                    <React.Fragment key={notification.id}>
                        <NavbarNotificationsItem notification={notification} user={user} />
                        <Dropdown.Divider className="NavbarNotifications__dropdown-divider" />
                    </React.Fragment>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
