import {atomFamily, selector, selectorFamily} from 'recoil';

import {ICourse} from 'modules/course/models';
import {readCourse} from 'modules/course/api';
import {courseListAtom} from './course-list';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';
import {tenantIdSelector} from 'modules/tenant/state/tenant-info';
import {ILanguage} from 'shared/utils/i18next-config';
import {currentLanguageAtom} from 'shared/state/current-language';

interface ICourseLookupState {
    course: ICourse;
    language: ILanguage;
}

export const courseLookupAtom = atomFamily<ICourseLookupState | undefined, number>({
    key: 'courseLookupAtom',
    default: undefined,
});

export const courseLoadingAtom = atomFamily<boolean, number>({
    key: 'courseLoadingAtom',
    default: false,
});

export const courseNotFoundAtom = atomFamily<boolean, number>({
    key: 'courseNotFoundAtom',
    default: false,
});

export const courseBatchNotFoundSelector = selectorFamily<number[], number[]>({
    key: 'courseBatchNotFoundSelector',
    get: (courseIds) => ({get}) => {
        return courseIds.filter(courseId => get(courseNotFoundAtom(courseId)));
    },
    set: () => ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        newValue.map(courseId => set(courseNotFoundAtom(courseId), true));
    },
});

export const missingCourseIdSelector = selectorFamily<number[], number[]>({
    key: 'missingCourseIdSelector',
    get: (courseIds) => ({get}) => {
        return courseIds
            .filter(courseId => !get(courseLookupSelector(courseId)))
            .filter(courseId => !get(courseNotFoundAtom(courseId)))
            .filter(courseId => !get(courseLoadingAtom(courseId)));
    },
});

export const courseBatchLookupSelector = selectorFamily<ICourse[], number[]>({
    key: 'courseBatchLookupSelector',
    get: (courseIds) => ({get}) => {
        return courseIds
            .map(courseId => get(courseLookupSelector(courseId)))
            .filter(course => !!course) as ICourse[];
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue.length) {
            return;
        }
        const language = get(currentLanguageAtom);
        newValue.forEach(course => set(courseLookupAtom(course.id), {course, language}));
    },
});

export const courseBatchSetSelector = selector<ICourse[]>({
    key: 'courseBatchSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue.length) {
            return;
        }
        const language = get(currentLanguageAtom);
        newValue.forEach(course => set(courseLookupAtom(course.id), {course, language}));
    },
});

export const courseBatchLoadingSetSelector = selector<number[]>({
    key: 'courseBatchLoadingSetSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        newValue.forEach(courseId => set(courseLoadingAtom(courseId), true));
    },
});

export const courseLookupSelector = selectorFamily<ICourse | undefined, number>({
    key: 'courseLookupSelector',
    get: (courseId) => ({get}) => {
        // try read from lookup
        const courseInLookup = get(courseLookupAtom(courseId));
        const currentLanguage = get(currentLanguageAtom);

        if (courseInLookup && courseInLookup.language === currentLanguage) {
            return courseInLookup.course;
        }

        // try read from course list
        const courseListState = get(courseListAtom);
        const courseInCourseList = courseListState?.courses.find(course => course.id === courseId);

        if (courseInCourseList && courseListState?.language === currentLanguage) {
            return courseInCourseList;
        }

        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (!newValue || guardRecoilDefaultValue(newValue)) {
            return;
        }
        const language = get(currentLanguageAtom);
        set(courseLookupAtom(newValue.id), {course: newValue, language});
    },
});

export const courseLookupReadSelector = selectorFamily<ICourse, number>({
    key: 'courseLookupSelector',
    get: (courseId) => async ({get}): Promise<ICourse> => {
        const currentValue = get(courseLookupSelector(courseId));
        if (currentValue) {
            return currentValue;
        }
        const tenantId = get(tenantIdSelector);
        const language = get(currentLanguageAtom);
        return await readCourse({tenantId,courseId}, language);
    },
});

export const courseLookupRemoveSelector = selector<number>({
    key: 'courseLookupRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, courseId) => {
        if (guardRecoilDefaultValue(courseId)) {
            return;
        }
        set(courseLookupAtom(courseId), undefined);
    },
});
