const readConfigEnvMaybe = (key: string): string | undefined => {
    if (key in process.env && process.env[key] && !!(process.env[key] as string).length) {
        return process.env[key] as string;
    } else {
        return undefined;
    }
};

const readConfigEnv = (key: string): string => {
    const envValue = readConfigEnvMaybe(key);
    if (envValue) {
        return envValue;
    } else {
        throw new Error(`Missing environment variable ${key}`);
    }
};

const readConfigNumber = (key: string): number => {
    const environValue = readConfigEnv(key);
    const result = parseInt(environValue);
    if (isNaN(result)) {
        throw new Error(`Invalid environment variable ${key}: Expected a number, got "${environValue}"`);
    }
    return result;
};

export const Config = {
    appName: readConfigEnv('REACT_APP_NAME'),
    authStorageKey: readConfigEnv('REACT_APP_AUTH_STORAGE_KEY'),
    authCookieName: readConfigEnv('REACT_APP_AUTH_COOKIE_NAME'),
    helpLinkUrl: readConfigEnv('REACT_APP_HELP_URL'),
    apiURL: readConfigEnv('REACT_APP_API_URL'),
    tenantId: readConfigEnvMaybe('REACT_APP_TENANT_ID'),
    passwordLengthMin: readConfigNumber('REACT_APP_PASSWORD_LENGTH_MIN'),
    passwordLengthMax: readConfigNumber('REACT_APP_PASSWORD_LENGTH_MAX'),
    courseServiceURL: readConfigEnv('REACT_APP_COURSE_SERVICE_URL'),
    notificationServiceURL: readConfigEnv('REACT_APP_NOTIFICATION_SERVICE_URL'),
    userServiceURL: readConfigEnv('REACT_APP_USER_SERVICE_URL'),
    tenantServiceURL: readConfigEnv('REACT_APP_TENANT_SERVICE_URL'),
    fileServiceURL: readConfigEnv('REACT_APP_FILE_SERVICE_URL'),
    liveSessionServiceURL: readConfigEnv('REACT_APP_LIVE_SESSION_SERVICE_URL'),
    certificateServiceURL: readConfigEnv('REACT_APP_CERTIFICATE_SERVICE_URL'),
    marketingServiceURL: readConfigEnv('REACT_APP_MARKETING_SERVICE_URL'),
};
