import React from 'react';

import {Container, Navbar} from 'react-bootstrap';

import './style.scss';

interface ISubNavbarProps {
    children?: React.ReactNode;
    size?: 'lg';
    fluid?: boolean;
}

export const SubNavbar = ({children, size, fluid}: ISubNavbarProps) => {
    const containerClassName = size === 'lg' ? 'container--lg' : '';

    return (
        <Navbar className="SubNavbar bg-white">
            <Container className={containerClassName} fluid={fluid}>
                {children}
            </Container>
        </Navbar>
    );
};
