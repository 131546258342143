import axios from 'axios';

import {ISearchListQuery, ISearchListResult} from '../models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {getApiUrlForPath, getAxiosHeaders} from 'shared/utils/api';

export const readSearch = async (searchQuery: ISearchListQuery): Promise<ISearchListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getApiUrlForPath(`/tenants/${searchQuery.tenantId}/search/`), {
        params: {
            search_term: searchQuery.searchTerm,
            limit: searchQuery.limit,
            cursor: searchQuery.cursor,
        },
        headers: getAxiosHeaders(accessToken),
    });
    return {
        documents: response.data.documents,
        more: response.data.more,
        nextCursor: response.data.next_cursor,
    };
};
