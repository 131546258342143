import { useRecoilLoadable } from 'shared/recoil/hooks/useRecoilLoadable';
import { IRecordListFilters, recordListReadSelector, recordListSelector } from '../state/record-list';

export const useRecordListLoadable = (filters: IRecordListFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: recordListSelector(filters),
        loadable: recordListReadSelector(filters),
    });
    return {
        records: data,
        loading,
        error,
    };
};