import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronLeft, faChevronRight, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';

interface IChevronProps {
    direction: 'left' | 'right' | 'up' | 'down';
    className?: string;
    size?: SizeProp;
}

export const Chevron = ({className, direction, size}: IChevronProps) => {
    let icon;
    if (direction === 'left') {
        icon = faChevronLeft;
    } else if (direction === 'right') {
        icon = faChevronRight;
    } else if (direction === 'up') {
        icon = faChevronUp;
    } else {
        icon = faChevronDown;
    }
    return (
        <FontAwesomeIcon icon={icon} className={className} size={size} />
    );
};
