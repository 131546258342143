import React from 'react';

import {Config} from 'shared/config';

export const NavbarSidemenuFooter = () => (
    <div className="NavbarSidemenu__footer">
        <a href={Config.helpLinkUrl} target="_blank" rel="noreferrer" className="NavbarSidemenu__footer-link">
            <img src="/assets/images/logo@2x.png" alt="Cloudschool" className="NavbarSidemenu__footer-image" />
            <div className="NavbarSidemenu__footer-caption">Cloudschool</div>
        </a>
    </div>
);
