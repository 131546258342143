import {atom, selector, selectorFamily} from 'recoil';

import {IUser} from 'modules/user/models/user';
import {userListUserSelector} from './user-list';
import {readUser} from '../api/user';
import {tenantIdSelector} from '../../tenant/state/tenant-info';
import {userInsertSelector} from './user-insert';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';

interface IUserLookupAtomState {
    [key: number]: IUser;
}

export const userLookupAtom = atom<IUserLookupAtomState>({
    key: 'userLookupAtom',
    default: {},
});

export const userLookupSelector = selectorFamily<IUser | undefined, number>({
    key: 'userLookupSelector',
    get: (userId) => ({get}) => {
        const usersLookupState = get(userLookupAtom);
        return usersLookupState?.[userId];
    },
    set: (userId: number) => ({get, set}, newValue) => {
        if (!newValue || guardRecoilDefaultValue(newValue)) {
            return;
        }
        const usersLookupState = get(userLookupAtom);
        const newState = {...usersLookupState};
        newState[userId] = newValue;
        set(userLookupAtom, newState);
    },
});

export const userLookupInsertSelector = selector<IUser>({
    key: 'userLookupInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return null;
        }
        set(userLookupSelector(newValue.id), newValue);
    },
});

export const userLookupReadSelector = selectorFamily<IUser, number>({
    key: 'userLookupReadSelector',
    get: (userId) => async ({get}): Promise<IUser> => {

        const userInLookup = get(userLookupSelector(userId));
        if (userInLookup) {
            return userInLookup;
        }

        const userInList = get(userListUserSelector(userId));
        if (userInList) {
            return userInList;
        }

        const tenantId = get(tenantIdSelector);
        return await readUser(tenantId, userId);
    },
    set: () => ({get, set}, newValue) => {
        if (!newValue || guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(userInsertSelector, newValue);
    },
});

export const userLookupReadMaybeSelector = selectorFamily<IUser | undefined, number | undefined>({
    key: 'userLookupReadMaybeSelector',
    get: (userId) => async ({get})=> {
        if (!userId) {
            return;
        }

        return get(userLookupReadSelector(userId));
    },
    set: () => ({get, set}, newValue) => {
        if (!newValue || guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(userInsertSelector, newValue);
    },
});

export const userLookupRemoveSelector = selector<number>({
    key: 'userLookupRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const userLookupState = {...get(userLookupAtom)};
        if (newValue in userLookupState) {
            delete userLookupState[newValue];
        }
        set(userLookupAtom, userLookupState);
    },
});

export const userLookupBatchRemoveSelector = selector<number[]>({
    key: 'userLookupBatchRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const userLookupState = {...get(userLookupAtom)};
        newValue.forEach(userId => {
            if (userId in userLookupState) {
                delete userLookupState[userId];
            }
        });
        set(userLookupAtom, userLookupState);
    },
});
