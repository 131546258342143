import axios from 'axios';

import {getAxiosOptions} from 'shared/utils/api';
import {IResetPasswordCompleteInput, IUser} from 'modules/user/models/user';
import {
    IPasswordUpdate,
    IResetPasswordInput,
    IResetPasswordResponse,
    IResetPasswordTokenPath,
} from '../models/password';
import {getAccessTokenSilently} from 'shared/utils/token';
import {Config} from 'shared/config';

export const getPasswordUrl = (tenantId: number, path: string | number) => {
    return `${Config.userServiceURL}/tenants/${tenantId}/password${path}`;
};

export const resetPassword = async (resetPasswordInput: IResetPasswordInput): Promise<IResetPasswordResponse> => {
    const response = await axios.post(getPasswordUrl(resetPasswordInput.tenantId, '/reset'), {
        username: resetPasswordInput.username,
    });
    return response.data;
};

export const resetPasswordComplete = async (resetPasswordCompleteInput: IResetPasswordCompleteInput) => {
    await axios.post(getPasswordUrl(
        resetPasswordCompleteInput.tenantId,
        `/reset/${resetPasswordCompleteInput.tokenId}`,
    ), {
        password: resetPasswordCompleteInput.password,
    });
};

export const readResetPasswordTokenExists = async (resetPasswordTokenPath: IResetPasswordTokenPath) => {
    await axios.get(getPasswordUrl(resetPasswordTokenPath.tenantId, `/reset/${resetPasswordTokenPath.tokenId}`));
};

export const updatePassword = async (tenantId: number, passwordUpdate: IPasswordUpdate): Promise<IUser> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getPasswordUrl(tenantId, '/update'),
        passwordUpdate,
        getAxiosOptions(accessToken),
    );
    return response.data;
};
