
export enum RecordStatus {
    PASS = 'PASS',
    FAIL = 'FAIL',
    PENDING = 'PENDING',
}

interface IRecordMetadata {
    course_name: string;
}

export interface IRecord {
    tenant_id: number;
    course_id: number;
    user_id: number;
    stint_version: number;
    status: RecordStatus;
    metadata: IRecordMetadata;
    created_by: number;
    created_at: string;
    updated_at: string;
}

export interface IRecordCreate {
    user_id: number;
    course_id: number;
    created_by: number;
}

export interface IRecordListQuery {
    user_id?: number;
    course_id?: number;
    status?: RecordStatus;
    stint_version?: number;
    cursor?: string;
    limit?: number;

    [key: string]: number | string | undefined;
}

export interface IRecordListResult {
    records: IRecord[];
    next_cursor?: string;
    more: boolean;
}

export interface IRecordKey {
    courseId: number;
    userId: number;
    stintVersion: number;

    [key: string]: number;
}