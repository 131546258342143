import React from 'react';

import {Dropdown} from 'react-bootstrap';

type DropdownToggle = typeof Dropdown['Toggle']['prototype'];

type Props = {
    id: string;
    className?: string;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const CustomToggle = React.forwardRef<DropdownToggle, Props>(({id, children, className, onClick}, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
        id={id}
        className={className}
        href="#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));
