import React from 'react';

export const TestPageGrays = () => {
    return (
        <>
            <h4 className="mb-4">Grays</h4>
            <div className="d-flex justify-content-between">
                <div className="block bg-gray-100" />
                <div className="block bg-gray-200" />
                <div className="block bg-gray-300" />
                <div className="block bg-gray-400" />
                <div className="block bg-gray-500" />
                <div className="block bg-gray-600" />
                <div className="block bg-gray-700" />
                <div className="block bg-gray-800" />
                <div className="block bg-gray-900" />
            </div>
        </>
    );
};
