import axios from 'axios';

import {getAccessTokenSilently} from 'shared/utils/token';
import {getAxiosHeaders, getAxiosOptions} from 'shared/utils/api';
import {
    IBatchNotificationUpdates,
    INotification,
    INotificationKey,
    INotificationListQuery,
    INotificationListResult,
    IUpdateNotification,
} from 'modules/notification/models';
import {Config} from '../../../shared/config';

export const getNotificationUrl = (tenantId: number, path: string | number) => {
    return `${Config.notificationServiceURL}/tenants/${tenantId}/notifications${path}`;
};

// export async function readNotifications(tenantId: number): Promise<INotification[]> {
//     const accessToken = await getAccessTokenSilently();
//     const response = await axios.get(getNotificationUrl(tenantId, '/'), {
//         headers: getAxiosHeaders(accessToken),
//     });
//     return response.data?.notifications as INotification[] ?? [];
// }

export async function readNotificationCount(
    tenantId: number,
    notificationListQuery?: INotificationListQuery,
): Promise<number> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getNotificationUrl(tenantId, '/count'), {
        params: {
            user_id: notificationListQuery?.userId,
            is_seen: notificationListQuery?.isSeen,
            is_read: notificationListQuery?.isRead,
        },
        headers: getAxiosHeaders(accessToken),
    });
    return response.data.count;
}

export async function readNotifications(
    tenantId: number,
    notificationListQuery?: INotificationListQuery,
): Promise<INotificationListResult> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getNotificationUrl(tenantId, '/'), {
        params: {
            user_id: notificationListQuery?.userId,
            is_seen: notificationListQuery?.isSeen,
            is_read: notificationListQuery?.isRead,
            cursor: notificationListQuery?.cursor,
            limit: notificationListQuery?.limit,
        },
        headers: getAxiosHeaders(accessToken),
    });
    return {
        notifications: response.data.notifications,
        nextCursor: response.data.next_cursor,
        more: response.data.more,
    };
}

export async function readNotification({tenantId, notificationId}: INotificationKey): Promise<INotification> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getNotificationUrl(tenantId, `/${notificationId}`), getAxiosOptions(accessToken));
    return response.data;
}

export async function updateNotification(
    tenantId: number,
    notificationId: number,
    updateData: IUpdateNotification,
): Promise<INotification> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getNotificationUrl(tenantId, `/${notificationId}`),
        updateData,
        getAxiosOptions(accessToken),
    );
    return response.data;
}

export async function deleteNotification(tenantId: number, notificationId: number): Promise<void> {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(getNotificationUrl(tenantId, `/${notificationId}`), getAxiosOptions(accessToken));
}

export const compareNotificationFilters = (filters1?: INotificationListQuery, filters2?: INotificationListQuery) => {
    if (!filters1 || !filters2) {
        return filters1 === filters2;
    }
    return (
        filters1.limit === filters2.limit &&
        filters1.cursor === filters2.cursor &&
        filters1.isSeen === filters2.isSeen &&
        filters1.isRead === filters2.isRead
    );
};

export async function markAllNotificationsAsRead(tenantId: number): Promise<void> {
    const accessToken = await getAccessTokenSilently();
    await axios.post(
        getNotificationUrl(tenantId, '/mark_all_as_read'),
        null,
        getAxiosOptions(accessToken),
    );
}

export const batchUpdateNotifications = async (
    tenantId: number,
    notificationUpdates: IBatchNotificationUpdates,
): Promise<INotification[]> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getNotificationUrl(tenantId, '/batch'),
        notificationUpdates,
        getAxiosOptions(accessToken),
    );
    return response.data;
};
