import {ICreateFile, IFile} from '../models/file';
import {Config} from 'shared/config';

interface IBatchFileDownloadUrls {
    [key: string]: string;
}

export const getFileDownloadUrl = (tenantId: number, fileId: number, fileName: string, token?: string): string => {
    const fixedFileName = fileName.replaceAll(' ', '-');
    let downloadURL = `${Config.fileServiceURL}/tenants/${tenantId}/files/${fileId}/download/${fixedFileName}`;
    if (token) {
        downloadURL += `?token=${token}`;
    }
    return downloadURL;
};

export const bacthGetFileDownloadUrls = (files: IFile[] | undefined): IBatchFileDownloadUrls => {
    const urls = {} as IBatchFileDownloadUrls;
    if (files) {
        files.forEach(file => {
            urls[`${file.id}`] = getFileDownloadUrl(file.tenant_id, file.id, file.filename);
        });
    }
    return urls;
};

export const createFileFromBlob = ({uploadUrl, blob}: ICreateFile): Promise<void> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', uploadUrl, true);
        xhr.onload = () => {
            const status = xhr.status;
            if (status === 200) {
                resolve();
            } else {
                reject('Could not upload file');
            }
        };
        xhr.onerror = () => {
            reject('Could not upload file');
        };
        xhr.setRequestHeader('Content-Type', blob.type);
        xhr.send(blob);
    });
};
