import axios from 'axios';

import {getAccessTokenSilently} from 'shared/utils/token';
import {getApiUrlForPath, getAxiosHeaders, getAxiosOptions} from 'shared/utils/api';
import {
    ICourse,
    ICourseBatchQuery,
    ICourseBatchResult,
    ICourseKey,
    ICourseListQuery,
    ICourseListResult,
    ICreateCourseForm,
    IUpdateCourseForm,
} from 'modules/course/models';
import {getFileDownloadUrl} from 'modules/file/utils';
import {CoverArtSize, getCoverArtUrl} from '../../cover-art';
import {ILanguage} from 'shared/utils/i18next-config';

export const courseCoverImageWidth = 2000;
export const courseCoverImageHeight = 600;

interface IGetCourseCoverUrlProps {
    course: ICourse;
    isEdit?: boolean;
    size: CoverArtSize;
}

export const getCourseUrl = (tenantId: number, path: string | number) => {
    return getApiUrlForPath(`/tenants/${tenantId}/courses${path}`);
};

export async function createCourse(tenantId: number, courseData: ICreateCourseForm): Promise<ICourse> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getCourseUrl(tenantId, '/'), courseData, getAxiosOptions(accessToken));
    return response.data;
}

export async function readCourseList(
    tenantId: number,
    language: ILanguage,
    readCoursesRequest?: ICourseListQuery,
): Promise<ICourseListResult> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getCourseUrl(tenantId, '/'), {
        params: {
            tenant_id: tenantId,
            language: language.code,
            is_published: readCoursesRequest?.isPublished,
            is_archived: readCoursesRequest?.isArchived,
            cursor: readCoursesRequest?.cursor,
            limit: readCoursesRequest?.limit,
            search_term: readCoursesRequest?.searchTerm,
        },
        headers: getAxiosHeaders(accessToken),
    });
    return {
        courses: response.data.courses,
        nextCursor: response.data.next_cursor,
        more: response.data.more,
        numberFound: response.data.number_found,
    };
}

export async function readCourse({tenantId, courseId}: ICourseKey, language: ILanguage): Promise<ICourse> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getCourseUrl(tenantId, `/${courseId}`), {
        params: {
            language: language.code,
        },
        ...getAxiosOptions(accessToken),
    });
    return response.data;
}

export async function updateCourse(
    tenantId: number,
    courseId: number,
    updateData: IUpdateCourseForm,
): Promise<ICourse> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getCourseUrl(tenantId, `/${courseId}`),
        updateData,
        getAxiosOptions(accessToken),
    );
    return response.data;
}

export async function deleteCourse({tenantId, courseId}: ICourseKey): Promise<ICourse> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.delete(getCourseUrl(tenantId, `/${courseId}`), getAxiosOptions(accessToken));
    return response.data;
}

export const getCourseCoverUrl = ({course, isEdit, size}: IGetCourseCoverUrlProps) => {
    if (course.cover?.file_id && course.cover?.filename) {
        return getFileDownloadUrl(course.tenant_id, course.cover.file_id, course.cover.filename);
    } else if (course.cover?.static_path) {
        return getCoverArtUrl(course.cover.static_path, size);
    } else {
        return `/assets/images/course/cover-default-${isEdit ? 'edit' : 'public'}.png`;
    }
};

export const compareCourseFilters = (filters1?: ICourseListQuery, filters2?: ICourseListQuery) => {
    if (!filters1 || !filters2) {
        return filters1 === filters2;
    }
    return (
        filters1.limit === filters2.limit &&
        filters1.cursor === filters2.cursor &&
        filters1.isPublished === filters2.isPublished &&
        filters1.isArchived === filters2.isArchived &&
        filters1.searchTerm === filters2.searchTerm
    );
};

export async function readCourseBatch(tenantId: number, courseIds: number[]): Promise<ICourseBatchResult> {
    const accessToken = await getAccessTokenSilently();
    const courseBatchQuery: ICourseBatchQuery = {
        course_ids: courseIds,
    };
    const response = await axios.post(
        getCourseUrl(tenantId, '/batch'),
        courseBatchQuery,
        getAxiosOptions(accessToken),
    );
    return response.data;
}

export const duplicateCourse = async (tenantId: number, courseId: number): Promise<ICourse> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getCourseUrl(tenantId, `/${courseId}/duplicate`),
        undefined,
        getAxiosOptions(accessToken),
    );
    return response.data;
};