import React from 'react';

import {useRecoilStateLoadable, useRecoilValueLoadable, useSetRecoilState} from 'recoil';

import {ITenant, ITenantInfo} from '../models';
import {tenantInfoAtom, tenantInfoSelector} from '../state';
import {readTenantInfoLocalStorage} from '../utils';
import {tenantToTenantInfo} from '../mappers';
import {Config} from 'shared/config';
import {tenantInfoReadSelector} from '../state/tenant-info';
import { Params, useParams } from 'react-router-dom';

interface IUseTenantInfoValue {
    tenantInfo: ITenantInfo;
    setTenantInfo: (tenantInfo: ITenantInfo | ITenant) => void;
}

export const useTenantInfo = (): IUseTenantInfoValue => {
    const setTenantInfoState = useSetRecoilState(tenantInfoAtom);
    const tenantInfoLoadable = useRecoilValueLoadable(tenantInfoReadSelector);
    const tenantInfoInCache = React.useMemo(() => readTenantInfoLocalStorage(), []);
    const tenantInfo = tenantInfoLoadable.valueMaybe();

    const setTenantInfo = (tenantOrTenantInfo: ITenant | ITenantInfo) => {
        if (!(tenantInfo)) {
            throw new Error('Tenant info is not ready yet');
        }
        const tenantInfoUpdate = ('system' in tenantOrTenantInfo) ? tenantOrTenantInfo : tenantToTenantInfo(
            tenantOrTenantInfo as ITenant,
            tenantInfo.system,
        );
        setTenantInfoState(tenantInfoUpdate);
    };

    // Always return the value from the server once its loaded even if it is undefined. Only return the cached value
    // while loading. It's possible that the cached value has been deleted on the server.
    const tenantInfoResult = React.useMemo<ITenantInfo | undefined>(() => {
        if (tenantInfoLoadable.state === 'hasValue') {
            return tenantInfoLoadable.contents;
        } else if (tenantInfoInCache) {
            // Config.tenantId can change while testing on localhost, so we need to check it matches the cached value
            // before using it
            if (Config.tenantId && tenantInfoInCache.id.toString() !== Config.tenantId) {
                console.warn('tenant info in local storage is for a different tenant');
                return undefined;
            } else {
                return tenantInfoInCache;
            }
        }
    }, [tenantInfoInCache, tenantInfoLoadable]);

    // store tenant info in localstorage once loaded
    React.useEffect(() => {
        if (tenantInfoResult) {
            setTenantInfoState(tenantInfoResult);
        }
    }, [tenantInfoResult, setTenantInfoState]);

    if (tenantInfoResult && tenantInfoLoadable.state !== 'hasError') {
        return {
            tenantInfo: tenantInfoResult,
            setTenantInfo,
        };
    } else if (tenantInfoLoadable.state === 'loading') {
        throw tenantInfoLoadable.toPromise();
    } else {
        throw new Error('Tenant not found');
    }
};

export const useTenantInfoMaybe = (): ITenantInfo | undefined => {
    const [tenantInfoLoadable, setTenantInfoState] = useRecoilStateLoadable(tenantInfoSelector);
    const tenantInfoInCache = React.useMemo(() => readTenantInfoLocalStorage(), []);
    const tenantInfo = tenantInfoLoadable.valueMaybe();

    // store tenant info in localstorage once loaded
    React.useEffect(() => {
        if (tenantInfo) {
            setTenantInfoState(tenantInfo);
        }
    }, [tenantInfo, setTenantInfoState]);

    // Always return the value from the server once its loaded even if it is undefined. Only return the cached value
    // while loading. It's possible that the cached value has been deleted on the server.
    return (tenantInfoLoadable.state === 'hasValue') ? tenantInfo : tenantInfoInCache;
};

export const useTenantIdFromPath = (): number => {
    const {tenantId} = useParams() as Params<'tenantId'>;
    const tenantIdPath = parseInt(tenantId as string);
    return tenantIdPath;
};