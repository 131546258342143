import {atom, DefaultValue, selector, selectorFamily} from 'recoil';

import {IAssignment, IAssignmentKey, IAssignmentListQuery} from 'modules/assignment/models';
import {compareAssignmentListQueries, readAssignmentList} from 'modules/assignment/api';
import {CursorList} from 'shared/models/cursor-list';
import {assignmentCountAtom, assignmentCountSelector, assignmentCountResetAtom} from './assignment-count';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';
import {tenantIdSelector} from 'modules/tenant/state/tenant-info';

export interface IAssignmentListFilters {
    filters?: IAssignmentListQuery;
    page: number;

    [key: string]: number | IAssignmentListQuery | undefined;
}

interface IAssignmentListState {
    filters: IAssignmentListQuery;
    assignments: IAssignment[];
    cursors: CursorList;
    page: number;
    more: boolean;
    numberFound: number;
    _resetVersion: number;

    [key: string]: number | boolean | IAssignment[] | CursorList | IAssignmentListQuery;
}

export const assignmentListAtom = atom<IAssignmentListState | undefined>({
    key: 'assignmentListAtom',
    default: undefined,
});

export const assignmentListResetAtom = atom<number>({
    key: 'assignmentListResetAtom',
    default: 0,
});

export const assignmentListSelector = selectorFamily<IAssignmentListState | undefined, IAssignmentListFilters>({
    key: 'assignmentListSelector',
    get: ({page, filters}) => ({get}) => {
        const atomValue = get(assignmentListAtom);
        const resetVersion = get(assignmentListResetAtom);
        if (
            atomValue &&
            page === atomValue.page &&
            atomValue._resetVersion === resetVersion &&
            compareAssignmentListQueries(filters, atomValue.filters)
        ) {
            return atomValue;
        }
        return undefined;
    },
    set: (_) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return null;
        }
        set(assignmentListAtom, newValue);
    },
});

export const assignmentListReadSelector = selectorFamily<IAssignmentListState, IAssignmentListFilters>({
    key: 'assignmentListReadSelector',
    get: (filters) => async ({get}): Promise<IAssignmentListState> => {
        const currentValue = get(assignmentListSelector(filters));
        if (currentValue) {
            return currentValue;
        }

        const atomValue = get(assignmentListAtom);
        const resetVersion = get(assignmentListResetAtom);

        // load a fresh page from the server
        const tenantId = get(tenantIdSelector);
        const result = await readAssignmentList(tenantId, {
            ...filters.filters,
            cursor: filters.page === 0 || !atomValue?.cursors?.length ? undefined : atomValue.cursors[filters.page - 1],
        });

        // add the cursor to the list of cursors so we can paginate backwards
        const newCursors = Array.from(atomValue?.cursors ?? []);
        newCursors[filters.page] = result.nextCursor;

        return {
            filters: filters.filters,
            assignments: result.assignments,
            cursors: newCursors,
            page: filters.page,
            more: result.more,
            _resetVersion: resetVersion,
        } as IAssignmentListState;
    },
});

export const assignmentListInsertSelector = selector<IAssignment>({
    key: 'assignmentListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        // if the assignment is present in the store then update it. Otherwise, force a reload.
        const atomValue = get(assignmentListAtom);
        if (!atomValue) {
            return;
        }
        const assignmentIndex = atomValue.assignments.findIndex(assignment => (
            assignment.user_id === newValue.user_id &&
            assignment.course_id === newValue.course_id
        ));
        if (assignmentIndex !== -1) {
            const newAssignments = Array.from(atomValue.assignments);
            newAssignments.splice(assignmentIndex, 1, newValue);
            set(assignmentListAtom, {
                ...atomValue,
                assignments: newAssignments,
            });
        } else {
            set(assignmentListResetAtom, get(assignmentListResetAtom) + 1);
        }
        const assignmentCount = get(assignmentCountAtom(newValue.course_id));
        if (assignmentCount) {
            set(
                assignmentCountSelector(newValue.course_id),
                assignmentCount.count + 1,
            );
        } else {
            set(
                assignmentCountResetAtom(newValue.course_id),
                get(assignmentCountResetAtom(newValue.course_id)) + 1,
            );
        }

    },
});

export const assignmentListRemoveSelector = selector<IAssignmentKey>({
    key: 'assignmentListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, assignmentKey) => {
        if (assignmentKey instanceof DefaultValue) {
            return;
        }

        // if the assignment is present in the store then remove it. Otherwise force a reload.
        const atomValue = get(assignmentListAtom);
        if (!atomValue) {
            return;
        }

        const assignmentIndex = atomValue.assignments.findIndex(assignment => (
            assignment.user_id === assignmentKey.user_id &&
            assignment.course_id === assignmentKey.course_id
        ));
        if (assignmentIndex !== -1) {
            set(assignmentListAtom, {
                ...atomValue,
                assignments: atomValue.assignments.filter(assignment => !(
                    assignment.user_id === assignmentKey.user_id &&
                    assignment.course_id === assignmentKey.course_id
                )),
                numberFound: atomValue.numberFound - 1,
            });
        } else {
            set(assignmentListResetAtom, get(assignmentListResetAtom) - 1);
        }
        const assignmentCount = get(assignmentCountAtom(assignmentKey.course_id));
        if (assignmentCount) {
            set(
                assignmentCountSelector(assignmentKey.course_id),
                assignmentCount.count - 1,
            );
        } else {
            set(
                assignmentCountResetAtom(assignmentKey.course_id),
                get(assignmentCountResetAtom(assignmentKey.course_id)) + 1,
            );
        }
    },
});