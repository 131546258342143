import {atom, selector, selectorFamily} from 'recoil';

import {readNotificationCount} from 'modules/notification/api';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';

interface IUnreadNotificationCountFilters {
    tenantId: number;
    userId: number;

    [key: string]: number;
}

interface IUnreadNotificationCountState {
    tenantId: number;
    userId: number;
    hasLoaded: boolean;
    unreadCount: number;
    _resetVersion: number;

    [key: string]: number | boolean;
}

export const unreadNotificationCountAtom = atom<IUnreadNotificationCountState>({
    key: 'unreadNotificationCountAtom',
    default: {
        tenantId: 0,
        userId: 0,
        hasLoaded: false,
        unreadCount: 0,
        _resetVersion: 0,
    },
});

export const unreadNotificationCountResetAtom = atom<number>({
    key: 'unreadNotificationCountResetAtom',
    default: 0,
});

export const unreadNotificationCountSelector = selectorFamily<number, IUnreadNotificationCountFilters>({
    key: 'unreadNotificationCountSelector',
    get: ({tenantId, userId}) => async ({get}): Promise<number> => {
        const notificationListState = get(unreadNotificationCountAtom);
        const resetVersion = get(unreadNotificationCountResetAtom);
        if (
            notificationListState.hasLoaded &&
            tenantId === notificationListState.tenantId &&
            userId === notificationListState.userId &&
            notificationListState._resetVersion === resetVersion
        ) {
            return notificationListState.unreadCount;
        }

        return await readNotificationCount(tenantId, {
            userId,
            isRead: false,
        });
    },
    set: ({tenantId, userId}) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return null;
        }
        const resetVersion = get(unreadNotificationCountResetAtom);
        const notificationListState = get(unreadNotificationCountAtom);
        if (
            notificationListState.tenantId !== tenantId ||
            notificationListState.userId !== userId ||
            notificationListState.unreadCount !== newValue ||
            !notificationListState.hasLoaded ||
            notificationListState._resetVersion !== resetVersion
        ) {
            set(unreadNotificationCountAtom, {
                tenantId,
                userId,
                unreadCount: newValue,
                _resetVersion: resetVersion,
                hasLoaded: true,
            });
        }
    },
});

export const unreadNotificationCountRemoveSelector = selector<number>({
    key: 'unreadNotificationCountRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return null;
        }
        const unreadNotificationCountState = get(unreadNotificationCountAtom);
        if (unreadNotificationCountState.hasLoaded) {
            const unreadCount = unreadNotificationCountState.unreadCount - 1;
            set(unreadNotificationCountAtom, {
                ...unreadNotificationCountState,
                unreadCount: unreadCount < 0 ? 0 : unreadCount,
            });
        }
    },
});
