import React from 'react';

import {Button, ButtonGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';

export interface IPaginationFilters {
    page: number;
    limit: number;
    showing?: number;
}

interface IPaginationProps {
    filters: IPaginationFilters;
    isLoading?: boolean;
    totalExisting?: number;
    onChange: (page: number) => void;
}

export const Pagination = ({
    filters,
    totalExisting,
    onChange,
    isLoading,
    children,
}: React.PropsWithChildren<IPaginationProps>) => {
    const {t} = useTranslation();

    const {limit, page, showing} = filters;

    const start = React.useMemo<number | undefined>(() => {
        if (!limit) {
            return;
        }
        return page * limit + 1;
    }, [page, limit]);

    const end = React.useMemo<number | undefined>(() => {
        if (!limit || !showing) {
            return;
        }
        return page * limit + Math.min(limit, showing);
    }, [page, limit, showing]);

    const totalExistingString = !!totalExisting ? ` of ${totalExisting}` : '';
    const showingString = isLoading ? '' : `Showing ${start} - ${end}`;

    return (
        <div className="Pagination d-flex align-items-center table-controls">
            {children}
            <span className={classNames('font-weight-bold', {'font-weight-bold ml-auto mr-3': !!children})}>
                {t(
                    'pagination.showingCount',
                    '{{showing}}{{total}}',
                    {showing: showingString, total: totalExistingString},
                )}
            </span>
            <ButtonGroup
                aria-label="Notification Pagination Buttons"
                className={classNames('Pagination__button', {'ml-auto Pagination__button': !children})}
                size="sm"
            >
                <Button
                    id="Pagination.prevActivity"
                    variant="secondary"
                    onClick={() => onChange(page - 1)}
                    disabled={page === 0 || isLoading}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <Button
                    id="Pagination.nextActivity"
                    variant="secondary"
                    onClick={() => onChange(page + 1)}
                    disabled={!end || (!!totalExisting && end >= totalExisting) || end < (limit * (page + 1)) || isLoading}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </ButtonGroup>
        </div>
    );
};
