import React from 'react';

import {useRecoilStateLoadable} from 'recoil';
import {Dropdown, Form, FormControl} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {useCombobox} from 'downshift';
import classNames from 'classnames';
import {useNavigate} from 'react-router';
import {useLocation} from 'react-router-dom';

import {useTenantInfo} from 'modules/tenant/hooks';
import useDebounce from 'shared/hooks/useDebounce';
import {ISearchDocument} from '../../../../modules/search/models';
import {searchSelector} from '../../../../modules/search/state';
import {getSearchResultUrl} from '../../../../modules/search/utils';

import {TooltipIcon} from '../../TooltipIcon/TooltipIcon';
import {NavbarSearchSuggestion} from './NavbarSearchSuggestion';

import './style.scss';

export const NavbarSearch = () => {
    const {t} = useTranslation();

    const {tenantInfo} = useTenantInfo();
    const navigate = useNavigate();

    const [hasSuccess, setHasSuccess] = React.useState<boolean>(false);
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const debouncedSearchTerm = useDebounce(searchTerm, 200);

    const location = useLocation();
    React.useEffect(() => setSearchTerm(''), [location]);

    const [searchLoadable, setSearch] = useRecoilStateLoadable(searchSelector({
        tenantId: tenantInfo.id,
        searchTerm: debouncedSearchTerm ?? '',
        limit: 10,
    }));

    React.useEffect(() => {
        if (searchLoadable.state === 'hasValue') {
            setSearch(searchLoadable.contents);
        }
    }, [searchLoadable, setSearch]);

    const results: ISearchDocument[] = searchLoadable.state === 'hasValue' ? searchLoadable.contents.documents : [];
    const isLoading = (
        searchLoadable.state === 'loading' ||
        (!!searchTerm?.length && searchTerm !== debouncedSearchTerm)
    );
    const isError = searchLoadable.state === 'hasError';
    const hasResults = !!results.length;

    // Prevent an empty dropdown from appearing by tracking whether we have had a successful response
    React.useEffect(() => {
        if (!hasSuccess && !isError && hasResults && !!debouncedSearchTerm.length) {
            setHasSuccess(true);
        } else if (hasSuccess && isError) {
            setHasSuccess(false);
        } else if (hasSuccess && !debouncedSearchTerm.length) {
            setHasSuccess(false);
        }
    }, [hasResults, isLoading, isError, debouncedSearchTerm, hasSuccess]);

    const onSelectedItem = (suggestion: ISearchDocument) => {
        const url = getSearchResultUrl(suggestion);
        if (url) {
            navigate(url);
        }
    };

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
    } = useCombobox<ISearchDocument>({
        inputValue: searchTerm,
        items: results,
        onSelectedItemChange: ({selectedItem}) => selectedItem ? onSelectedItem(selectedItem) : undefined,
        isOpen: !debouncedSearchTerm.length ? false : undefined,
        itemToString: item => item?.name ?? '',
    });

    const dropdownMenuClassName = classNames('dropdown-menu dropdown-menu-right w-100 NavbarSearch__dropdown-menu', {
        'show': isOpen && hasSuccess,
    });

    return (
        <Form inline className="mr-3 d-none d-md-flex">
            {!tenantInfo.system.is_search_enabled && (
                <TooltipIcon
                    id="NavbarSearch.disabled"
                    icon={faExclamationTriangle}
                    placement="bottom"
                    size="lg"
                >
                    {t('navbar.search.disabledTooltip', 'Search is currently disabled')}
                </TooltipIcon>
            )}
            <Dropdown show={isOpen}>
                <div{...getComboboxProps()}>
                    <FormControl
                        {...getInputProps({
                            onChange: (e) => setSearchTerm((e.target as HTMLInputElement).value),
                        })}
                        type="text"
                        placeholder={t('common.search', 'Search')}
                        className="NavbarSearch__input"
                        disabled={!tenantInfo.system.is_search_enabled}
                    />
                </div>
                <ul {...getMenuProps({className: dropdownMenuClassName})}>
                    {isOpen && !!results.length && results.map((item, index) => (
                        <NavbarSearchSuggestion
                            {...getItemProps({
                                key: item.key,
                                index,
                                item,
                                style: {
                                    backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                                    fontWeight: selectedItem === item ? 'bold' : 'normal',
                                },
                            })}
                            suggestion={item}
                            highlighted={highlightedIndex === index}
                        />
                    ))}
                    {isOpen && hasSuccess && !results.length && !isLoading && (
                        <Dropdown.Item>No Results</Dropdown.Item>
                    )}
                </ul>
            </Dropdown>
        </Form>
    );
};
