import React from 'react';

import classNames from 'classnames';

import {getFileDownloadUrl} from 'modules/file/utils';
import {useTenantInfo} from 'modules/tenant/hooks';

import {ProfilePictureError} from './ProfilePictureError';

import './style.scss';

export interface IProfilePictureProps {
    imageId?: number | null;
    url?: string | null;
    fileName?: string;
    alt?: string;
    round?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'xmd' | 'lg' | 'xl' | 'xxl';
    border?: boolean;
    table?: boolean;
    className?: string,
    imgClassName?: string;
}


export const ProfilePicture = ({
    alt,
    border,
    imageId,
    fileName,
    round,
    size,
    url,
    table,
    className,
    imgClassName,
}: IProfilePictureProps) => {
    const [hasError, setHasError] = React.useState<boolean>(false);

    const classNameComputed = classNames('ProfilePicture', className, {
        'ProfilePicture--round': round,
        'ProfilePicture--border': border,
        'ProfilePicture--table': table,
        'ProfilePicture--error': hasError,
        [`ProfilePicture--${size}`]: size,
    });

    const {tenantInfo} = useTenantInfo();
    const imageUrl = (imageId)
        ? getFileDownloadUrl(tenantInfo.id, imageId, fileName || imageId.toString())
        : url;

    return (
        <>
            {hasError && (
                <ProfilePictureError className={classNameComputed} />
            )}
            {(!hasError && imageUrl) && (
                <img
                    src={imageUrl}
                    alt={alt}
                    className={`${classNameComputed} ${imgClassName || ''}`}
                    onError={() => setHasError(true)}
                />
            )}
            {(!hasError && !imageUrl) && (
                <div className={`${classNameComputed} ProfilePicture--placeholder ${imgClassName || ''}`} />
            )}
        </>
    );
};
