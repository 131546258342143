import {atom, DefaultValue, selector, selectorFamily} from 'recoil';

import {IUser, UserRole} from 'modules/user/models/user';
import {readUserList} from 'modules/user/api/user';
import {CursorList} from 'shared/models/cursor-list';
import {tenantIdSelector} from '../../tenant/state/tenant-info';
import {userLookupBatchRemoveSelector, userLookupRemoveSelector} from './user-lookup';
import {ArrayFilterOperation} from 'shared/models/array';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';

export interface IUserListFilters {
    page: number;
    role: UserRole;
    limit: number;
    searchTerm?: string;
    tagIds?: number[];
    tagOp?: ArrayFilterOperation;

    [key: string]: number | UserRole | undefined | string | number[];
}

interface IUserListState {
    users: IUser[];
    more: boolean;
    cursors: CursorList;
}

interface IUserListAtomState extends IUserListState {
    tenantId: number;
    filters: IUserListFilters;
    resetVersion: number;
}

export const userListAtom = atom<IUserListAtomState | undefined>({
    key: 'userListAtom',
    default: undefined,
});

export const userListResetAtom = atom<number>({
    key: 'userListResetAtom',
    default: 1,
});

export const userListAtomSelector = selectorFamily<IUserListState | undefined, IUserListFilters>({
    key: 'userListAtomSelector',
    get: (filters) => ({get}) => {
        const userListAtomValue = get(userListAtom);
        const tenantId = get(tenantIdSelector);
        if (userListAtomValue && userListAtomValue.tenantId === tenantId) {
            return {
                users: userListAtomValue.users,
                more: userListAtomValue.more,
                cursors: userListAtomValue.cursors,
            };
        } else {
            return undefined;
        }
    },
});

export const userListReadSelector = selectorFamily<IUserListState, IUserListFilters>({
    key: 'userListReadSelector',
    get: (filters) => async ({get}) => {
        const userListAtomValue = get(userListAtom);
        const tenantId = get(tenantIdSelector);
        const resetVersion = get(userListResetAtom);
        if (
            userListAtomValue &&
            JSON.stringify(filters) === JSON.stringify(userListAtomValue.filters) &&
            userListAtomValue.tenantId === tenantId &&
            userListAtomValue.resetVersion === resetVersion
        ) {
            return {
                users: userListAtomValue.users,
                more: userListAtomValue.more,
                cursors: userListAtomValue.cursors,
            };
        }
        const response = await readUserList({
            tenantId,
            limit: filters.limit,
            role: filters.role,
            search_term: filters.searchTerm,
            tag_ids: filters.tagIds,
            tags_op: filters.tagOp,
            cursor: (filters.page === 0 || !userListAtomValue?.cursors)
                ? undefined
                : userListAtomValue.cursors[filters.page - 1]
            ,
        });

        const cursors: CursorList = userListAtomValue ? [...userListAtomValue.cursors] : [];

        return {
            users: response.users,
            cursors: [...cursors, response.next_cursor],
            more: response.more,
        } as IUserListState;
    },
    set: (filters) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const userListAtomValue = get(userListAtom);
        const tenantId = get(tenantIdSelector);
        const resetVersion = get(userListResetAtom);
        if (
            !userListAtomValue ||
            JSON.stringify(userListAtomValue.filters) !== JSON.stringify(filters) ||
            userListAtomValue.users.length !== newValue.users.length ||
            userListAtomValue.tenantId !== tenantId
        ) {
            set(userListAtom, {
                filters,
                tenantId,
                resetVersion,
                ...newValue,
            });
        }
    },
});

export const userListUserSelector = selectorFamily<IUser | undefined, number>({
    key: 'userListUserSelector',
    get: (userId) => ({get}) => {
        const userListAtomValue = get(userListAtom);
        if (userListAtomValue) {
            return userListAtomValue.users.find(user => user.id === userId);
        } else {
            return undefined;
        }
    },
});

export const userListInsertSelector = selector<IUser>({
    key: 'userListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return null;
        }
        const userListAtomValue = get(userListAtom);
        if (userListAtomValue) {
            const users: IUser[] = Array.from(userListAtomValue.users);
            const index = users.findIndex(user => user.id === newValue.id);
            if (index !== -1) {
                users.splice(index, 1, newValue);
                set(userListAtom, {
                    ...userListAtomValue,
                    users,
                });
            } else {
                set(userListResetAtom, get(userListResetAtom) + 1);
            }
        }
    },
});

export const userListRemoveSelector = selector<number>({
    key: 'userListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, userId) => {
        if (userId instanceof DefaultValue) {
            return;
        }
        const userListState = get(userListAtom);
        if (!userListState) {
            return;
        }

        // if the user is present in the store then remove them. Otherwise force a reload.
        const userIndex = userListState.users.findIndex(user => user.id === userId);
        if (userIndex !== -1) {
            set(userListAtom, {
                ...userListState,
                users: userListState.users.filter(user => user.id !== userId),
            });
            set(userLookupRemoveSelector, userId);
        } else {
            set(userListResetAtom, get(userListResetAtom) + 1);
        }
    },
});

export const userListBatchRemoveSelector = selector<number[]>({
    key: 'userListBatchRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, userIds) => {
        if (userIds instanceof DefaultValue) {
            return;
        }
        const userListState = get(userListAtom);
        if (!userListState) {
            return;
        }

        const newUsers = userListState.users.filter(user => !userIds.includes(user.id));
        set(userListAtom, {...userListState, users: newUsers});
        set(userLookupBatchRemoveSelector, userIds);
    },
});

export const userListResetSelector = selector<number>({
    key: 'userListResetSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}) => {
        set(userListResetAtom, get(userListResetAtom) + 1);
    },
});