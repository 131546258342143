import {ITenantInfo} from '../models';

export const tenantInfoLocalStorageKey = 'tenantInfo';

export function setTenantInfoLocalStorage(tenantInfo: ITenantInfo) {
    localStorage.setItem(tenantInfoLocalStorageKey, JSON.stringify(tenantInfo));
}

export function removeTenantInfoLocalStorage() {
    localStorage.removeItem(tenantInfoLocalStorageKey);
}

export function readTenantInfoLocalStorage(): ITenantInfo | undefined {
    const tenantInfoData = localStorage.getItem(tenantInfoLocalStorageKey);
    if (tenantInfoData) {
        try {
            return JSON.parse(tenantInfoData);
        } catch (e) {
        }
    }
    return undefined;
}
