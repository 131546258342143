import React from 'react';

import {Table} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import {useRecordListLoadable} from 'modules/record/hooks/useRecordListLoadable';

import {IPaginationFilters, Pagination} from 'shared/components/Pagination/Pagination';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {CourseRecordTableRow} from './CourseRecordTableRow';

import './styles.scss';

interface ICourseRecordTableProps {
    userId: number;
}

export const CourseRecordTable = ({userId}: ICourseRecordTableProps) => {
    const {t} = useTranslation();
    const defaultFilters = {
        page: 0,
        limit: 10,
    };
    const [filters, setFilters] = React.useState<IPaginationFilters>(defaultFilters);
    const {records, loading, error} = useRecordListLoadable({
        filters: {
            user_id: userId,
            limit: filters.limit,
        },
        page: filters.page,
    });

    const paginationFilters: IPaginationFilters = {
        page: filters.page,
        limit: filters.limit,
        showing: records?.records.length,
    };

    return (
        <div className='pt-5'>
            <h2>{t('common.learnerHistory', 'Learner History')}</h2>
            <p>{t('courseRecordTable.description', 'A detailed report of the learner\'s course completion history.')}</p>
            {error && (
                <div>Error</div>
            )}
            {loading && (
                <LoadingSpinner />
            )}
            {!records?.records.length && (
                <p>{t('courseRecordTable.empty', 'No courses completed yet.')}</p>
            )}
            {!!records?.records.length && (
                <>
                    <Pagination
                        filters={paginationFilters}
                        onChange={(page) => setFilters({...filters, page})}
                    />
                    <Table className='border'>
                        <thead>
                            <tr>
                                <th>{t('common.courseName', 'Course Name')}</th>
                                <th>{t('common.date', 'Date')}</th>
                                <th className="CourseRecordTable__status--title">
                                    {t('common.status', 'Status')}
                                </th>
                                <th className="CourseRecordTable__download">
                                    {t('common.viewRecord', 'View Record')}
                                </th>
                                <th className="CourseRecordTable__download">
                                    {t('common.downloadCertificate', 'Download Certificate')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.records.map(record => (
                                <CourseRecordTableRow key={JSON.stringify(record)} record={record} />
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
        </div>
    );
};