import React from 'react';

import {Container} from 'react-bootstrap';

import {useNavbar} from 'shared/hooks/useNavbar';

export const Contact = () => {
    useNavbar(true);

    return (
        <Container>
            <h1>Contact</h1>
        </Container>
    );
};
