export enum ProgressVariant {
    SUCCESS = 'success',
    INFO = 'info',
    DANGER = 'danger',
}

export const getProgressPercent = (completed?: number, total?: number): number => {
    if (completed && total) {
        return (completed / total) * 100;
    } else {
        return 0;
    }
};

export const getProgressTitle = (completed?: number, total?: number): string => {
    if (completed !== undefined && total !== undefined) {
        return `${completed} of ${total}`;
    } else {
        return '';
    }
};

export const getProgressTooltip = (completed?: number, total?: number, text?: string): string => {
    if (completed !== undefined && total !== undefined) {
        return `${completed} of ${total} ${text ? text : ''} completed`;
    } else {
        return 'Loading...';
    }
};

export const getProgressVariant = (completed?: number, total?: number) => {
    if (!(completed && total) || getProgressPercent(completed, total) === 0) {
        return ProgressVariant.DANGER;
    } else if (getProgressPercent(completed, total) === 100) {
        return ProgressVariant.SUCCESS;
    } else {
        return ProgressVariant.INFO;
    }
};
