import {useTenantInfo} from 'modules/tenant/hooks';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {ProfilePictureSharedProps} from './TenantProfilePicture';

export const TenantProfilePictureImage = ({imgClassName, ...rest}: ProfilePictureSharedProps) => {
    const {tenantInfo} = useTenantInfo();

    return (
        <ProfilePicture
            fileName={tenantInfo.image?.filename || tenantInfo.name}
            imageId={tenantInfo.image?.file_id}
            alt={tenantInfo.name}
            imgClassName={imgClassName} {...rest}
        />
    );
};
