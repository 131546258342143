import React from 'react';

import { Alert, Card, Container } from 'react-bootstrap';
import { Link, Params, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useRecoilValueLoadable } from 'recoil';

import { useTitle } from 'shared/hooks/useTitle';
import { getAxiosErrorOrThrow } from 'shared/utils/error';
import { confirmChangeEmailSelector } from 'modules/user/state/change-email';
import { useTenantInfo } from 'modules/tenant/hooks';

import { Spinner } from 'shared/components/Spinner/Spinner';

import './style.scss';

export const ChangeEmailConfirmation = () => {
    const {t} = useTranslation();

    const navigate = useNavigate();
    const {tenantInfo} = useTenantInfo();
    const {tokenId} = useParams() as Params<'tokenId'>;

    const confirmChangeEmailLoadable = useRecoilValueLoadable(confirmChangeEmailSelector({
        tenantId: tenantInfo.id,
        tokenId: tokenId as string,
    }));

    useTitle(t('changeEmail.browserTitle', 'Confirm Email Address'));

    React.useEffect(() => {
        if (confirmChangeEmailLoadable.state === 'hasValue') {
            toast.success(t('changeEmail.toast.success', 'Email changed successfully.'));
            navigate('/settings');
        } else if (confirmChangeEmailLoadable.state === 'hasError') {
            toast.error(getAxiosErrorOrThrow(confirmChangeEmailLoadable.errorMaybe()));
        }
    }, [confirmChangeEmailLoadable, t, navigate]);

    return (
        <Container className="ChangeEmailConfirmation">
            <Card>
                <Card.Body className="ChangeEmailConfirmation__card-body">
                    <h1 className="ChangeEmailConfirmation__heading">{t(
                        'changeEmail.pageTitle',
                        'Confirm Email Address',
                    )}</h1>
                    {confirmChangeEmailLoadable.state === 'loading' && (
                        <div className="text-center">
                            <Spinner/>
                        </div>
                    )}
                    {confirmChangeEmailLoadable.state === 'hasError' && (
                        <Alert variant="warning">
                            <Trans i18nKey="changeEmail.error.messages">
                                The link has expired or is not correct. Try copying and pasting the link into your
                                browser, or try <Link to="/settings">change your email</Link> again.
                            </Trans>
                        </Alert>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};
