import React from 'react';

import {useTenantInfo} from 'modules/tenant/hooks';

interface ITenantInfoProviderInnerProps {
    children?: React.ReactNode;
}

export function TenantInfoProviderInner({children}: ITenantInfoProviderInnerProps) {
    useTenantInfo();

    return (
        <>{children}</>
    );
}
