import {atomFamily, selector, selectorFamily} from 'recoil';

import {IAssignmentProgress} from '../models';
import {readAssignmentProgress} from '../api';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';
import {tenantIdSelector} from '../../tenant/state/tenant-info';

export interface IAssignmentProgressKey {
    userId: number;
    courseId: number;

    [key: string]: number;
}

export interface IAssignmentProgressState {
    assignmentProgress: IAssignmentProgress;
    _resetVersion: number;
}

export const assignmentProgressAtom = atomFamily<IAssignmentProgressState | undefined, IAssignmentProgressKey>({
    key: 'assignmentProgressAtom',
    default: undefined,
});

export const assignmentProgressResetAtom = atomFamily<number, IAssignmentProgressKey>({
    key: 'assignmentProgressResetAtom',
    default: 0,
});

export const assignmentProgressSelector = selectorFamily<IAssignmentProgressState | undefined, IAssignmentProgressKey>({
    key: 'assignmentProgressSelector',
    get: (assignmentProgressKey) => ({get}) => {
        const atomValue = get(assignmentProgressAtom(assignmentProgressKey));
        const resetVersion = get(assignmentProgressResetAtom(assignmentProgressKey));
        if (
            atomValue &&
            atomValue._resetVersion === resetVersion
        ) {
            return atomValue;
        }
        return undefined;
    },
    set: (assignmentProgressKey) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(assignmentProgressAtom(assignmentProgressKey), newValue);
    },
});

export const assignmentProgressReadSelector = selectorFamily<IAssignmentProgressState, IAssignmentProgressKey>({
    key: 'assignmentProgressReadSelector',
    get: (assignmentProgressKey) => async ({get}) => {
        const currentValue = get(assignmentProgressSelector(assignmentProgressKey));
        if (currentValue) {
            return currentValue;
        }
        const tenantId = get(tenantIdSelector);
        
        const response = await readAssignmentProgress({
            tenantId,
            userId: assignmentProgressKey.userId,
            courseId: assignmentProgressKey.courseId,
        });

        const resetVersion = get(assignmentProgressResetAtom(assignmentProgressKey));

        return {
            assignmentProgress: response,
            _resetVersion: resetVersion,
        } as IAssignmentProgressState;
    },
});


export const assignmentProgressResetInsertSelector = selector<IAssignmentProgressKey>({
    key: 'assignmentProgressResetInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, assignmentProgressKey) => {
        if (guardRecoilDefaultValue(assignmentProgressKey)) {
            return;
        }
        set(assignmentProgressResetAtom(assignmentProgressKey), get(assignmentProgressResetAtom(assignmentProgressKey)) + 1);
    },
});