import {atom, DefaultValue, selector, selectorFamily} from 'recoil';

import {INotification, INotificationKey} from 'modules/notification/models';
import {readNotification} from 'modules/notification/api';
import {notificationInNotificationListSelector} from './notification-list';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';

interface INotificationLookupState {
    [key: number]: INotification;
}

export const notificationLookupAtom = atom<INotificationLookupState>({
    key: 'notificationLookupAtom',
    default: {},
});

export const notificationLookupSelector = selectorFamily<INotification, INotificationKey>({
    key: 'notificationLookupSelector',
    get: ({tenantId, notificationId}) => async ({get}): Promise<INotification> => {
        // try read notification from notification lookup state
        const notificationLookupState = get(notificationLookupAtom);
        const notificationInLookup = notificationLookupState[notificationId];
        if (notificationInLookup.tenant_id === tenantId) {
            return notificationInLookup;
        }

        // try read notification from notification list
        const notificationInNotificationList = get(notificationInNotificationListSelector(notificationId));
        if (notificationInNotificationList?.tenant_id === tenantId) {
            return notificationInNotificationList;
        }

        // read notification from API
        return await readNotification({
            tenantId,
            notificationId,
        });
    },
    set: ({notificationId}) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const newNotificationLookup = {
            ...get(notificationLookupAtom),
        };
        newNotificationLookup[notificationId] = newValue;
        set(notificationLookupAtom, newNotificationLookup);
    },
});

export const notificationLookupRemoveSelector = selector<number>({
    key: 'notificationLookupRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, notificationId) => {
        if (notificationId instanceof DefaultValue) {
            return;
        }

        const newNotificationLookupState = Object.assign({}, get(notificationLookupAtom));
        delete newNotificationLookupState[notificationId];
        set(notificationLookupAtom, newNotificationLookupState);
    },
});
