import {ITenant} from 'modules/tenant/models';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';

interface INavbarSidemenuHeaderProps {
    tenant: ITenant;
}

export const NavbarSidemenuHeader = ({tenant}: INavbarSidemenuHeaderProps) => {
    return (
        <div className="NavSidemenu__header mb-5">
            <ProfilePicture
                className="NavbarSidemenu__header-image"
                alt={tenant.name}
                size="lg"
                imageId={tenant.image?.file_id}
                fileName={tenant.image?.filename}
                round
            />
            <h2>{tenant.name}</h2>
        </div>
    );
};
