import { useRecoilState } from 'recoil';

import { userLookupReadMaybeSelector } from 'modules/user/state/user-lookup';
import { UserRole } from 'modules/user/models/user';

export const useIsRegistered = (userId: number | undefined) => {
    const [user] = useRecoilState(userLookupReadMaybeSelector(userId));

    return (
        user && (
            !!user?.metadata?.is_registered ||
            user.roles.includes(UserRole.Admin) ||
            user.roles.includes(UserRole.TenantAdmin)
        )
    );
};