import {selectorFamily} from 'recoil';

import {IChangeEmailTokenPath} from '../models/email';
import {confirmChangeEmail} from '../api/email';

export const confirmChangeEmailSelector = selectorFamily<void, IChangeEmailTokenPath>({
    key: 'confirmChangeEmailSelector',
    get: (tokenPath) => async () => {
        return await confirmChangeEmail(tokenPath);
    },
});
