import React from 'react';

import {Badge, Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Scope} from 'modules/auth/models';

interface INavbarSidemenuItemProps {
    icon: IconProp;
    to?: string;
    href?: string;
    badge?: string | number;
    scopes?: Scope[];
    userScopes?: Scope[];
    newTab?: true;
}

export const NavbarSidemenuItem = ({
    children,
    to,
    icon,
    badge,
    scopes,
    userScopes,
    href,
    newTab,
}: React.PropsWithChildren<INavbarSidemenuItemProps>) => {
    const hasScope = !scopes || !userScopes || scopes.find(scope => userScopes.includes(scope));

    let as: React.ElementType | undefined;
    if (href) {
        as = 'a';
    } else {
        as = Link;
    }

    return (
        <>
            {hasScope && (
                <Nav.Item>
                    <Nav.Link
                        as={as}
                        to={to}
                        href={href}
                        className="NavbarSidemenu__link d-flex align-items-center"
                        target={(href && newTab) ? '_blank' : undefined}
                    >
                        <FontAwesomeIcon icon={icon} size="lg" className="NavbarSidemenu__link-icon mr-4" fixedWidth />
                        {children}
                        {!!badge && (
                            <Badge className="NavbarSidemenu__link-badge ml-2 mr-auto" variant="danger">{badge}</Badge>
                        )}
                    </Nav.Link>
                </Nav.Item>
            )}
        </>
    );
};
