import React from 'react';

import classNames from 'classnames';

import './style.scss';

interface IEmptyMessageProps {
    className?: string;
}

export const EmptyMessage = ({children, className}: React.PropsWithChildren<IEmptyMessageProps>) => {
    const classNameComputed = classNames('EmptyMessage', className);

    return (
        <div className={classNameComputed}>
            {children}
        </div>
    );
};
