import {selectorFamily} from 'recoil';

import {INotificationListResult} from 'modules/notification/models';
import {readNotifications} from 'modules/notification/api';

interface IUnseenNotificationListKey {
    tenantId: number;
    userId: number;

    [key: string]: number;
}

export const unseenNotificationSelector = selectorFamily<INotificationListResult, IUnseenNotificationListKey>({
    key: 'unseenNotificationSelector',
    get: ({tenantId, userId}) => async (): Promise<INotificationListResult> => {
        return await readNotifications(tenantId, {
            limit: 10,
            userId: userId,
            isSeen: false,
        });
    },
});
