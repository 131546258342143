import React from 'react';

import {useTranslation} from 'react-i18next';
import {Card} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {useSetRecoilState} from 'recoil';

import {markAllNotificationsAsRead} from 'modules/notification/api';
import {useGuaranteedAuth} from 'shared/hooks/useAuth';
import {formatAxiosErrorOrThrow} from 'shared/utils/error';
import {markAllNotificationsAsReadSelector} from 'modules/notification/state/notification-list';

export const NavbarNotificationsMarkAsRead = () => {
    const {t} = useTranslation();
    const {tenantId} = useGuaranteedAuth();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const setNotifications = useSetRecoilState(markAllNotificationsAsReadSelector);

    const handleUpdate = () => {
        const isRead = true;
        setIsSubmitting(true);
        (async () => {
            try {
                await markAllNotificationsAsRead(tenantId);
            } catch (e) {
                toast.error(formatAxiosErrorOrThrow(
                    t('coursePage.errors.updateNotification', 'There was an error updating the notification'), e,
                ));
                setIsSubmitting(false);
                return;
            }
            setNotifications(true);
            setIsSubmitting(false);
            if (isRead) {
                toast.success(t('notificationPage.notificationsSuccess', 'Notifications marked as read'));
            } else {
                toast.warning(
                    t('notificationPage.notificationWarning', 'There was an error updating the notification', {
                        context: 'hidden',
                    }),
                );
            }
        })();
    };

    return (
        <>
            <Card.Link href="#/markAsRead" onClick={handleUpdate} className="ml-2">
                {isSubmitting ?
                    t('navbarNotifications.markingAsRead', 'Marking as Read...')
                    :
                    t('navbarNotifications.markAsRead', 'Mark as Read')}
            </Card.Link>
            <span> | </span>
        </>
    );
};
