import {atom, selectorFamily} from 'recoil';

import {ISearchListQuery, ISearchListResult} from '../models';
import {readSearch} from '../api';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';

interface ISearchState {
    filters: ISearchListQuery;
    result: ISearchListResult;
}

export const searchAtom = atom<ISearchState | undefined>({
    key: 'searchAtom',
    default: undefined,
});

export const searchSelector = selectorFamily<ISearchListResult, ISearchListQuery>({
    key: 'searchSelector',
    get: (filters) => async ({get}): Promise<ISearchListResult> => {
        if (!filters.searchTerm?.length) {
            return {
                documents: [],
                more: false,
                nextCursor: undefined,
            };
        }
        const searchState = get(searchAtom);
        if (searchState && JSON.stringify(searchState.filters) === JSON.stringify(filters)) {
            return searchState.result;
        } else {
            return await readSearch(filters);
        }
    },
    set: (filters) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const searchState = get(searchAtom);
        if (!searchState || JSON.stringify(searchState.filters) !== JSON.stringify(filters)) {
            set(searchAtom, {
                filters,
                result: newValue,
            });
        }
    },
});
