export enum SearchDocumentKind {
    Course = 'COURSE',
    User = 'USER',
}

export interface ISearchDocument {
    tenant_id: number;
    key: string;
    id: number;
    kind: SearchDocumentKind;
    name: string;
    description?: string;
}

export interface ISearchListQuery {
    tenantId: number;
    searchTerm?: string;
    isPublished?: boolean;
    limit: number;
    cursor?: string;

    [key: string]: number | string | boolean | undefined;
}

export interface ISearchListResult {
    documents: ISearchDocument[];
    more: boolean;
    nextCursor?: string;
}
