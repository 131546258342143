import {atom, selector, selectorFamily} from 'recoil';

import {ITenant} from '../models';
import {readTenant} from '../api';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';

export const tenantAtom = atom<{ [key: number]: ITenant | undefined }>({
    key: 'tenantAtom',
    default: {},
});

export const tenantSelector = selectorFamily<ITenant, number>({
    key: 'tenantSelector',
    get: (tenantId: number) => async ({get}): Promise<ITenant> => {
        const tenantsInStore = get(tenantAtom);
        return tenantsInStore[tenantId] ?? await readTenant(tenantId);
    },
    set: (tenantId: number) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const tenantsInStore = get(tenantAtom);
        const newTenantsInStore = Object.assign({}, tenantsInStore);
        newTenantsInStore[tenantId] = newValue;
        set(tenantAtom, newTenantsInStore);
    },
});

export const tenantLookupInsertSelector = selector<ITenant>({
    key: 'tenantLookupInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const tenantsInStore = {...get(tenantAtom)};
        tenantsInStore[newValue.id] = newValue;
        set(tenantAtom, tenantsInStore);
    },
});
