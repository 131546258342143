import React from 'react';

import {ErrorBoundary} from 'react-error-boundary';

import {TenantInfoProviderInner} from './TenantInfoProviderInner';
import {NotFoundPage} from 'shared/pages/NotFoundPage/NotFoundPage';
import {LoadingPageFallback} from 'shared/components/loading/LoadingPage/LoadingPageFallback';

interface ITenantInfoProviderProps {
    children?: React.ReactNode;
}

export function TenantInfoProvider({children}: ITenantInfoProviderProps) {
    return (
        <React.Suspense
            fallback={(
                <LoadingPageFallback />
            )}
        >
            <ErrorBoundary
                fallbackRender={() => (
                    <NotFoundPage />
                )}
            >
                <TenantInfoProviderInner>
                    {children}
                </TenantInfoProviderInner>
            </ErrorBoundary>
        </React.Suspense>
    );
}
