import React from 'react';

import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import {ProgressSliderLoading} from './ProgressSliderLoading/ProgressSliderLoading';

import './style.scss';

export interface IProgressSliderProps {
    id: string;
    loading?: boolean;
    percent: number;
    title: React.ReactNode;
    variant: string;
    wide?: boolean;
    center?: boolean;
    onClick?: () => void;
    tooltip: React.ReactNode;
    className?: string;
    to?: string;
}

export const ProgressSlider = ({
    id,
    tooltip,
    loading,
    percent,
    title,
    variant,
    wide,
    center,
    onClick,
    className,
    to,
}: IProgressSliderProps) => {

    const progressBarClassName = classNames('ProgressSlider__bar progress', {
        'ProgressSlider__bar--wide': wide && !center,
        'ProgressSlider__bar--wide-centered': wide && center,
    });

    const progressClassName = classNames(`ProgressSlider__progress progress-bar bg-${variant}`, {
        'ProgressSlider__progress--wide': wide,
        'ProgressSlider__progress--center': center,
    });

    const bubbleClassName = classNames(`ProgressSlider__bubble bg-${variant}`, {
        'ProgressSlider__bubble--wide': wide,
        'ProgressSlider__bubble--center': center,
    });

    return (
        <div className={classNames('ProgressSlider', className)}>
            <div className={progressBarClassName}>
                <div className={progressClassName} style={{width: `${center ? '100' : percent}%`}}>
                    <OverlayTrigger
                        placement="top"
                        overlay={(props) => (
                            <Tooltip id={id} {...props}>
                                {tooltip}
                            </Tooltip>
                        )}
                    >
                        {({ref, ...triggerHandler}) => (
                            <Link
                                className={bubbleClassName}
                                to={to ? to : '#'}
                                onClick={onClick}
                                ref={ref} {...triggerHandler}>
                                {loading ? (
                                    <ProgressSliderLoading />
                                ) : (
                                    title
                                )}
                            </Link>
                        )}
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    );
};
