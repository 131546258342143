import {ISearchDocument, SearchDocumentKind} from '../models';

export const getSearchResultUrl = (searchResult: ISearchDocument): string | undefined => {
    if (searchResult.kind === SearchDocumentKind.Course) {
        return `/courses/${searchResult.id}`;
    } else if (searchResult.kind === SearchDocumentKind.User) {
        return `/users/${searchResult.id}`;
    } else {
        return undefined;
    }
};

export const getSearchResultDescription = (suggestion: ISearchDocument): string | undefined => {
    if (suggestion.kind === SearchDocumentKind.User) {
        return suggestion.description;
    } else {
        return suggestion.kind;
    }
};
