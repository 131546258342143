import React from 'react';

import {Navigate} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {useAuth} from 'shared/hooks/useAuth';
import {authRedirectAtom} from '../../../../modules/auth/state';

interface IRequireGuestProps {
    children: React.ReactNode;
    redirect?: string;
}

export const RequireGuest = ({children, redirect}: IRequireGuestProps) => {
    const {isAuthenticated} = useAuth();

    const [authRedirect, setAuthRedirect] = useRecoilState(authRedirectAtom);

    if (isAuthenticated) {
        const redirectToUse = authRedirect || redirect || '/';
        setAuthRedirect(undefined);
        return (
            <Navigate to={{pathname: redirectToUse}} />
        );
    } else {
        return (
            <>{children}</>
        );
    }
};
