import React from 'react';

import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Button, Nav} from 'react-bootstrap';

import {IUser} from 'modules/user/models/user';
import {useAuth} from 'shared/hooks/useAuth';
import {Scope} from 'modules/auth/models';
import {isIntersection} from 'shared/utils/array';

import {SubNavbar} from 'shared/components/SubNavbar/SubNavbar';
import {Chevron} from 'shared/components/Chevron/Chevron';

interface IMyProfileSubNavProps {
    user: IUser;
}

export const MyProfileSubNav = ({user}: IMyProfileSubNavProps) => {
    const {t} = useTranslation();
    const {tokenData} = useAuth();

    const isAdmin = isIntersection(tokenData?.scopes, [Scope.TenantEdit, Scope.RoleAdmin]);

    return (
        <SubNavbar>
            <Nav className="mr-auto">
                {isAdmin ? (
                    <Button size="sm" variant="secondary" as={Link} to="/users" className="mr-2">
                        <Chevron direction="left" className="mr-2" />
                        {t('common.users', 'Users')}
                    </Button>
                ) : ''}
                <Button size="sm" variant="secondary" as={Link} to="/settings">
                    {t('common.accountSettings', 'Account Settings')}
                </Button>
            </Nav>
        </SubNavbar>
    );
};
