import React from 'react';

import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import 'shared/utils/i18next-config';
import reportWebVitals from './reportWebVitals';

import { LoadingPage } from 'shared/components/loading/LoadingPage/LoadingPage';
import { TenantInfoProvider } from 'shared/components/TenantInfoProvider/TenantInfoProvider';
import { AuthProvider } from 'shared/components/AuthProvider/AuthProvider';
import { LanguageSetter } from 'shared/components/LanguageSetter/LanguageSetter';
import { AccessTokenHelper } from 'shared/components/AuthProvider/AccessTokenHelper';
import { App } from 'App';

import 'styles/_main.scss';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <BrowserRouter>
                <div className="position-relative vh-100 d-flex flex-column">
                    <TenantInfoProvider>
                        <AuthProvider>
                            <AccessTokenHelper />
                            <LanguageSetter>
                                <App/>
                            </LanguageSetter>
                        </AuthProvider>
                    </TenantInfoProvider>
                    <LoadingPage/>
                </div>
            </BrowserRouter>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
