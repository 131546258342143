import React from 'react';

import {Spinner} from 'shared/components/Spinner/Spinner';

interface ILoadingSpinnerProps {
    className?: string;
    size?: 'sm';
}

export const LoadingSpinner = ({className, size}: ILoadingSpinnerProps) => {
    return (
        <div className={`text-center ${className ?? 'py-3'}`}>
            <Spinner size={size} />
        </div>
    );
};
