import {useEffect} from 'react';

import {useSetRecoilState} from 'recoil';

import {loadingPageState} from '../../state/loading-page';

export const useLoadingPage = (show: boolean) => {
    const setShowLoadingPage = useSetRecoilState(loadingPageState);

    useEffect(() => {
        setShowLoadingPage(show);
        return () => {
            setShowLoadingPage(false);
        };
    }, [setShowLoadingPage, show]);
};
