import axios from 'axios';

import {
    IReadTenantsProps,
    IReadTenantsResult,
    ITenant,
    ITenantInfo,
    ITenantInfoByHostQuery,
    ITenantInfoByIDQuery,
    ITenantUpdate,
} from '../models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {getAxiosHeaders, getAxiosOptions} from 'shared/utils/api';
import {Config} from '../../../shared/config';

export const getTenantUrl = (path: string | number) => {
    return `${Config.tenantServiceURL}/tenants${path}`;
};

export const getTenantInfoURL = (path: string | number) => {
    return `${Config.tenantServiceURL}/tenant_info${path}`;
};

export const readTenantsAsync = async (readTenantsParams: IReadTenantsProps): Promise<IReadTenantsResult> => {
    try {
        const accessToken = await getAccessTokenSilently();
        const response = await axios.get(getTenantUrl('/'), {
            params: readTenantsParams,
            headers: getAxiosHeaders(accessToken),
        });
        return {
            tenants: response.data?.tenants ?? [],
            nextCursor: response.data?.next_cursor,
            more: response.data?.more,
        };
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export async function readTenant(tenantId: number): Promise<ITenant> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getTenantUrl(`/${tenantId}`), getAxiosOptions(accessToken));
    return response.data;
}

export async function updateTenant(tenantId: number, updateData: ITenantUpdate): Promise<ITenant> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getTenantUrl(`/${tenantId}`), updateData, getAxiosOptions(accessToken));
    return response.data;
}

export const readTenantInfo = async (tenantInfoQuery: ITenantInfoByHostQuery | ITenantInfoByIDQuery): Promise<ITenantInfo> => {
    const response = await axios.get(getTenantInfoURL('/'), {
        params: tenantInfoQuery,
        withCredentials: true,
    });
    return response.data;
};
