import React from 'react';

import {Dropdown} from 'react-bootstrap';
import {formatDistance} from 'date-fns';
import {toast} from 'react-toastify';
import {zonedTimeToUtc} from 'date-fns-tz';
import {useTranslation} from 'react-i18next';
import {useSetRecoilState} from 'recoil';

import {INotification} from 'modules/notification/models';
import {IUser} from 'modules/user/models/user';
import {getFullName} from 'modules/user/api/user';
import {notificationInsertSelector} from 'modules/notification/state/notification-insert';
import {updateNotification} from 'modules/notification/api';
import {formatAxiosErrorOrThrow} from 'shared/utils/error';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';

import './style.scss';

interface INavbarNotificationsItemProps {
    notification: INotification;
    user: IUser;
}

export const NavbarNotificationsItem = ({notification, user}: INavbarNotificationsItemProps) => {
    const userFullName = getFullName(user);
    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const setNotificationState = useSetRecoilState(notificationInsertSelector);

    const createdDate = zonedTimeToUtc(notification.created_at, 'UTC');
    const currentDate = new Date();
    const timeDifference = formatDistance(createdDate, currentDate);

    const handleUpdate = () => {
        const isRead = true;
        setIsSubmitting(true);
        (async () => {
            let updatedNotification: INotification;
            try {
                updatedNotification = await updateNotification(notification.tenant_id, notification.id, {
                    is_read: true,
                    is_seen: true,
                });
            } catch (e) {
                toast.error(formatAxiosErrorOrThrow(
                    t('notificationPage.errors.updateNotification', 'There was an error updating the notification'), e,
                ));
                setIsSubmitting(false);
                return;
            }
            setNotificationState(updatedNotification);
            setIsSubmitting(false);
            if (isRead) {
                toast.success(t('notificationPage.notificationSuccess', 'Notification marked as read'));
            } else {
                toast.warning(
                    t('notificationPage.notificationWarning', 'There was an error updating the notification', {
                        context: 'hidden',
                    }),
                );
            }
        })();
    };

    return (
        <Dropdown.Item
            onClick={handleUpdate}
            className={notification.is_read ? 'NavbarNotifications__dropdown-item-read' : 'NavbarNotifications__dropdown-item'}
            disabled={notification.is_read}
        >
            <div className="d-flex align-items-center" style={{height: '5rem', padding: '0'}}>
                <div className="flex-grow-1; padding:0 align-items-center">
                    <ProfilePicture
                        alt={userFullName}
                        imageId={user.image?.file_id}
                        fileName={user.image?.filename}
                        size="sm"
                        round
                    />
                    <small className="text-muted d-flex align-items-bottom">{timeDifference}</small>
                </div>
                <div className="flex-grow-1 text-left ml-3">
                    <span className="NavbarNotifications__dropdown-item-text">
                        {isSubmitting ? t(
                            'common.notificationDropdown.markingAsRead',
                            'Marking as read...',
                        ) : notification.message}
                    </span>
                </div>
            </div>
        </Dropdown.Item>
    );
};
