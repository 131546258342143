import React from 'react';

import {Dropdown} from 'react-bootstrap';
import classNames from 'classnames';

import {ISearchDocument} from 'modules/search/models';
import {getSearchResultDescription} from 'modules/search/utils';

interface INavbarSearchSuggestionProps {
    suggestion: ISearchDocument;
    highlighted: boolean;
}

export const NavbarSearchSuggestion = ({suggestion, highlighted}: INavbarSearchSuggestionProps) => {
    const description = getSearchResultDescription(suggestion);

    const className = classNames('NavbarSearch__suggestion', {
        'NavbarSearch__suggestion--highlighted': highlighted,
    });

    return (
        <Dropdown.Item className={className}>
            {suggestion.name} <span className="text-muted">({description})</span>
        </Dropdown.Item>
    );
};
