export interface IUpdateMarketingProfile {
    DOB?: string;
    school?: string;
    year?: number;
    phone?: string;
    gender?: Gender;
    interests?: string[];
    accept_terms?: boolean;
}

export enum ProfileInterests {
    GAMING = 'GAMING',
    TECHNOLOGY = 'TECHNOLOGY',
    BUSINESS = 'BUSINESS',
    ART = 'ART',
    COOKING = 'COOKING',
    READING = 'READING',
    WRITING = 'WRITING',
    MUSIC = 'MUSIC',
    SPORTS = 'SPORTS',
    TRAVEL = 'TRAVEL',
    NATURE = 'NATURE',
    FASHION = 'FASHION',
    OTHER = 'OTHER',
}

export interface IProfileType {
    STUDENT: 'STUDENT';
    ADMIN: 'ADMIN';
    TEACHER: 'TEACHER';
    MENTOR: 'MENTOR';
    BUSINESS: 'BUSINESS';
    PUBLIC_FREE: 'PUBLIC_FREE';
    PUBLIC_NOT_PURCHASED: 'PUBLIC_NOT_PURCHASED';
    PUBLIC_PURCHASED: 'PUBLIC_PURCHASED';
    OTHER: 'OTHER';
}

export interface IMarketingProfile {
    id: string;
    user_id: number | null;
    first_name: string;
    last_name: string;
    email: string;
    profile_type: IProfileType;
    DOB: string;
    school: string;
    year: number;
    phone: number | undefined;
    gender: Gender | undefined;
    interests: string[];
    acceptTerms: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string | undefined;
    is_deleted: boolean;
}

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER',
}