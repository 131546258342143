import axios from 'axios';

import { 
    ISignupPage,
    ISignupPageCreate,
    ISignupPageListQuery,
    ISignupPageListResult,
    ISignupPageSummary,
    ISignupPageUpdate,
} from 'modules/signup-page/models';
import { Config } from 'shared/config';
import { getAxiosOptions } from 'shared/utils/api';
import { getAccessTokenSilently } from 'shared/utils/token';

const getSignupPageUrl = (tenantId: number, path: string) => {
    return `${Config.userServiceURL}/tenants/${tenantId}/signup_pages${path}`;
};

export const readSignupPageList = async (
    tenantId: number,
    signupPageListQuery: ISignupPageListQuery,
): Promise<ISignupPageListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getSignupPageUrl(tenantId, '/'), {
            params: signupPageListQuery,
            ...getAxiosOptions(accessToken),
        },
    );
    return response.data;
};

export const readSignupPage = async (tenantId: number, signupPageId: number): Promise<ISignupPage> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getSignupPageUrl(tenantId, `/${signupPageId}`), 
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const createSignupPage = async (tenantId: number, signupPageCreate: ISignupPageCreate): Promise<ISignupPage> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getSignupPageUrl(tenantId, '/'),
        signupPageCreate,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const readSignupPageSummary = async (
    tenantId: number,
    signupPageId: number,
): Promise<ISignupPageSummary> => {
    const response = await axios.get(
        getSignupPageUrl(tenantId, `/${signupPageId}/summary`), 
    );
    return response.data;
};

export const updateSignupPage = async (tenantId: number, signupPageUpdate: ISignupPageUpdate): Promise<ISignupPage> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getSignupPageUrl(tenantId, '/'),
        signupPageUpdate,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const deleteSignupPage = async (tenantId: number, signupPageId: number): Promise<void> => {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(
        getSignupPageUrl(tenantId, `/${signupPageId}`),
        getAxiosOptions(accessToken),
    );
};