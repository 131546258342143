import {
    getProgressPercent,
    getProgressTitle,
    getProgressTooltip,
    getProgressVariant,
} from 'shared/utils/progress-slider';

import {ProgressSlider} from '../ProgressSlider/ProgressSlider';
import {useAssignmentProgressLoadable} from 'modules/assignment-progress/hooks/useAssignmentProgressLoadable';

interface IUserCourseProgressProps {
    courseId: number;
    userId: number;
}

export const UserCourseProgress = ({courseId, userId}: IUserCourseProgressProps) => {
    const {assignmentProgress} = useAssignmentProgressLoadable({
        courseId,
        userId,
    });

    return (
        <ProgressSlider
            id={`${userId}`}
            tooltip={
                assignmentProgress
                    ? getProgressTooltip(assignmentProgress.completed_count, assignmentProgress.total_count, 'topics')
                    : 'Loading...'
            }
            loading={!assignmentProgress}
            percent={
                assignmentProgress
                    ? getProgressPercent(assignmentProgress.completed_count, assignmentProgress.total_count)
                    : 0
            }
            title={
                assignmentProgress
                    ? getProgressTitle(assignmentProgress.completed_count, assignmentProgress.total_count)
                    : ''
            }
            variant={getProgressVariant(assignmentProgress?.completed_count, assignmentProgress?.total_count)}
            to={`${userId}/progress`}
        />
    );
};
