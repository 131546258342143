export enum CoverArtSize {
    Small = '180',
    Medium = '656',
    Large = '2000',
}

export interface ICoverArt {
    filename: string;
    name: string;
}

export const getCoverArtUrl = (filename: string, size: CoverArtSize) => {
    return `/assets/coverart/${filename}@${size}.jpg`;
};

export const coverArtLibrary: ICoverArt[] = [
    {
        filename: 'lightbulb',
        name: 'Zebra',
    },
    {
        filename: 'art',
        name: 'Zebra',
    },
    {
        filename: 'concert',
        name: 'Zebra',
    },
    {
        filename: 'pencils',
        name: 'Zebra',
    },
    {
        filename: 'mountains',
        name: 'Zebra',
    },
    {
        filename: 'bricks',
        name: 'Zebra',
    },
    {
        filename: 'money',
        name: 'Zebra',
    },
    {
        filename: 'lake2',
        name: 'Zebra',
    },
    {
        filename: 'spider',
        name: 'Zebra',
    },
    {
        filename: 'feet',
        name: 'Zebra',
    },
    {
        filename: 'computer',
        name: 'Zebra',
    },
    {
        filename: 'zebra',
        name: 'Zebra',
    },
    {
        filename: 'camer',
        name: 'Zebra',
    },
    {
        filename: 'city2',
        name: 'Zebra',
    },
    {
        filename: 'city3',
        name: 'Zebra',
    },
    {
        filename: 'city1',
        name: 'Zebra',
    },
    {
        filename: 'city4',
        name: 'Zebra',
    },
    {
        filename: 'city5',
        name: 'Zebra',
    },
    {
        filename: 'maple',
        name: 'Zebra',
    },
    {
        filename: 'flower',
        name: 'Zebra',
    },
    {
        filename: 'lights',
        name: 'Zebra',
    },
    {
        filename: 'glacier',
        name: 'Zebra',
    },
    {
        filename: 'pattern1',
        name: 'Zebra',
    },
    {
        filename: 'jump',
        name: 'Zebra',
    },
    {
        filename: 'sky',
        name: 'Zebra',
    },
    {
        filename: 'lake',
        name: 'Zebra',
    },
    {
        filename: 'pattern2',
        name: 'Zebra',
    },
    {
        filename: 'egypt',
        name: 'Zebra',
    },
    {
        filename: 'skatepark2',
        name: 'Zebra',
    },
    {
        filename: 'book',
        name: 'Zebra',
    },
    {
        filename: 'music',
        name: 'Zebra',
    },
    {
        filename: 'forest',
        name: 'Zebra',
    },
    {
        filename: 'ocean',
        name: 'Zebra',
    },
    {
        filename: 'sunflowers',
        name: 'Zebra',
    },
    {
        filename: 'puppy',
        name: 'Zebra',
    },
    {
        filename: 'skatepark1',
        name: 'Zebra',
    },
    {
        filename: 'tiger',
        name: 'Zebra',
    },
    {
        filename: 'map',
        name: 'Zebra',
    },
    {
        filename: 'giraffe',
        name: 'Zebra',
    },
    {
        filename: 'space3',
        name: 'Zebra',
    },
    {
        filename: 'mountains3',
        name: 'Zebra',
    },
    {
        filename: 'eggs',
        name: 'Zebra',
    },
    {
        filename: 'football',
        name: 'Zebra',
    },
    {
        filename: 'microphone',
        name: 'Zebra',
    },
    {
        filename: 'grass2',
        name: 'Zebra',
    },
    {
        filename: 'grass3',
        name: 'Zebra',
    },
    {
        filename: 'farm1',
        name: 'Zebra',
    },
    {
        filename: 'space1',
        name: 'Zebra',
    },
    {
        filename: 'food3',
        name: 'Zebra',
    },
    {
        filename: 'farm2',
        name: 'Zebra',
    },
    {
        filename: 'food5',
        name: 'Zebra',
    },
    {
        filename: 'food4',
        name: 'Zebra',
    },
    {
        filename: 'food6',
        name: 'Zebra',
    },
    {
        filename: 'space2',
        name: 'Zebra',
    },
    {
        filename: 'obama',
        name: 'Zebra',
    },
    {
        filename: 'mountains2',
        name: 'Zebra',
    },
    {
        filename: 'food1',
        name: 'Zebra',
    },
    {
        filename: 'forest2',
        name: 'Zebra',
    },
    {
        filename: 'book2',
        name: 'Zebra',
    },
    {
        filename: 'bee',
        name: 'Zebra',
    },
    {
        filename: 'food2',
        name: 'Zebra',
    },
    {
        filename: 'grassland',
        name: 'Zebra',
    },
    {
        filename: 'space4',
        name: 'Zebra',
    },
    {
        filename: 'pattern3',
        name: 'Zebra',
    },
];
