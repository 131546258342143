import React from 'react';

import {useRecoilValue} from 'recoil';

import {useTitle} from 'shared/hooks/useTitle';
import {getFullName} from 'modules/user/api/user';
import {useNavbar} from 'shared/hooks/useNavbar';
import {useGuaranteedAuth} from 'shared/hooks/useAuth';
import {userLookupReadSelector} from 'modules/user/state/user-lookup';

import {MyProfileSubNav} from './MyProfileSubNav';
import {ProfilePageContent} from 'shared/components/ProfilePageContent/ProfilePageContent';

export const MyProfile = () => {
    useNavbar(true);
    const {tokenData} = useGuaranteedAuth();
    const user = useRecoilValue(userLookupReadSelector(tokenData.id));

    const userFullName = getFullName(user);
    useTitle(userFullName);

    return (
        <>
            <MyProfileSubNav user={user} />
            <ProfilePageContent user={user} />
        </>
    );
};
