import {selector} from 'recoil';

import {IUser} from 'modules/user/models/user';
import {userListInsertSelector} from './user-list';
import {userLookupInsertSelector} from './user-lookup';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';

export const userInsertSelector = selector<IUser>({
    key: 'userInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        set(userListInsertSelector, newValue);
        set(userLookupInsertSelector, newValue);
    },
});
