import React from 'react';

import classNames from 'classnames';

import {Spinner} from 'shared/components/Spinner/Spinner';

import {NavbarSidemenuContent} from './NavbarSidemenuContent';

import './style.scss';

interface INavbarSidemenuProps {
    isOpen: boolean;
    onClose: () => void;
}

export const NavbarSidemenu = ({isOpen, onClose}: INavbarSidemenuProps) => {
    const className = classNames({
        'NavbarSidemenu__backdrop': isOpen,
    });
    const asideClassName = classNames('NavbarSidemenu__aside', {
        'NavbarSidemenu__aside--open': isOpen,
    });

    return (
        <div className={className} onClick={() => onClose()}>
            <aside className={asideClassName}>
                <React.Suspense
                    fallback={(
                        <div className="text-center py-5">
                            <Spinner />
                        </div>
                    )}
                >
                    <NavbarSidemenuContent />
                </React.Suspense>
            </aside>
        </div>
    );
};
