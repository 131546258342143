import React from 'react';

import {useRecoilValueLoadable, useSetRecoilState} from 'recoil';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

import {useGuaranteedAuth} from 'shared/hooks/useAuth';
import {IBatchNotificationUpdates} from 'modules/notification/models';
import {batchUpdateNotifications} from 'modules/notification/api';
import {formatAxiosErrorOrThrow} from 'shared/utils/error';
import {useTenantInfo} from 'modules/tenant/hooks';
import {unseenNotificationSelector} from 'modules/notification/state/unseen-notifications';
import {notificationInsertSelector} from 'modules/notification/state/notification-insert';

export const NotificationToasterContent = () => {
    const {tenantInfo} = useTenantInfo();
    const {tokenData} = useGuaranteedAuth();
    const {t} = useTranslation();
    const unseenNotificationsLoadable = useRecoilValueLoadable(unseenNotificationSelector({
        tenantId: tenantInfo.id,
        userId: tokenData.id,
    }));
    const setNotificationState = useSetRecoilState(notificationInsertSelector);

    React.useEffect(() => {
        if (unseenNotificationsLoadable.state !== 'hasValue') {
            return;
        }
        const unseenNotifications = unseenNotificationsLoadable.contents.notifications;
        (async () => {
            const notificationsData: IBatchNotificationUpdates = {
                tenant_id: tenantInfo.id,
                notification_updates: [],
            };
            try {
                notificationsData.notification_updates = unseenNotifications.map(notification => {
                    toast.success(t('notification.newNotification', `New Notification: ${notification.message}`));
                    return {
                        id: notification.id,
                        is_seen: true,
                        is_read: notification.is_read,
                    };
                });
                const updatedNotifications = await batchUpdateNotifications(tenantInfo.id, notificationsData);
                Array.from(updatedNotifications).forEach(notification => {
                    setNotificationState(notification);
                });
            } catch (e) {
                toast.error(formatAxiosErrorOrThrow(
                    t('notification.errors.updateNotification', 'There was an error updating your notifications'),
                    e,
                ));
                return;
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unseenNotificationsLoadable, setNotificationState]);

    return null;
};
