import axios from 'axios';

import {
    IAssignmentBulkCreate,
    IAssignmentBulkDelete,
    IAssignmentCountResult,
    IAssignmentListQuery,
    IAssignmentListResult,
} from 'modules/assignment/models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {getApiUrlForPath, getAxiosHeaders, getAxiosOptions, serializeArrayParams} from 'shared/utils/api';
import {ICourseKey} from 'modules/course/models';

export const getAssignmentUrl = (tenantId: number, path: string) => {
    return getApiUrlForPath(`/tenants/${tenantId}/assignments${path}`);
};

export const bulkCreateAssignments = async (
    tenantId: number,
    assignmentBulkCreate: IAssignmentBulkCreate,
): Promise<IAssignmentListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getAssignmentUrl(tenantId, '/batch/create'),
        assignmentBulkCreate,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const bulkDeleteAssignments = async (
    tenantId: number,
    assignmentBulkDelete: IAssignmentBulkDelete,
): Promise<void> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getAssignmentUrl(tenantId, '/batch/delete'),
        assignmentBulkDelete,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const readAssignmentList = async (
    tenantId: number,
    {tag_ids, ...assignmentListQuery}: IAssignmentListQuery,
): Promise<IAssignmentListResult> => {

    // manually serialize the URL parameters. This is required because axios serializes list parameters
    // differently to our API. Axios results in "course_ids[]=1&course_ids[]=2", whereas we need
    // "course_ids=1&course_ids=2".
    let serializedUrl = getAssignmentUrl(tenantId, '/');
    if (!!tag_ids) {
        serializedUrl = `${serializedUrl}?${serializeArrayParams('tag_ids', tag_ids)}`;
    }

    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(serializedUrl, {
        params: assignmentListQuery,
        headers: getAxiosHeaders(accessToken),
    });
    return {
        assignments: response.data.assignments,
        more: response.data.more,
        nextCursor: response.data.next_cursor,
    };
};

export const compareAssignmentListQueries = (query1?: IAssignmentListQuery, query2?: IAssignmentListQuery) => {
    if (!query1 || !query2) {
        return query1 === query2;
    }
    return (
        query1.limit === query2.limit &&
        query1.cursor === query2.cursor &&
        query1.user_id === query2.user_id &&
        query1.course_id === query2.course_id &&
        query1.is_assigned === query2.is_assigned &&
        query1.is_disabled === query2.is_disabled &&
        JSON.stringify(query1.tag_ids) === JSON.stringify(query2.tag_ids)
    );
};

export const readAssignmentCount = async ({tenantId, courseId}: ICourseKey): Promise<IAssignmentCountResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getAssignmentUrl(tenantId, '/count'), {
        params: {
            course_id: courseId,
        },
        headers: getAxiosHeaders(accessToken),
    });
    return response.data;
};

export const deleteAssignment = async (
    tenant_id: number,
    user_id: number,
    course_id: number,
): Promise<void> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.delete(getAssignmentUrl(tenant_id, `/${user_id}/${course_id}`), {
        params: {
            user_id,
            course_id,
        },
        headers: getAxiosHeaders(accessToken),
    });
    return response.data;
};

export const assignAssignment = async (
    tenant_id: number,
    user_id: number,
    course_id: number,
) => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getAssignmentUrl(tenant_id, `/${user_id}/${course_id}/assign`),
        undefined,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const unAssignAssignment = async (
    tenant_id: number,
    user_id: number,
    course_id: number,
) => {
    const accessToken = await getAccessTokenSilently();
    await axios.patch(
        getAssignmentUrl(tenant_id, `/${user_id}/${course_id}/un_assign`),
        undefined,
        getAxiosOptions(accessToken),
    );
};