import React from 'react';

import {RecoilState, RecoilValueReadOnly, useRecoilState, useRecoilValueLoadable} from 'recoil';

interface IUseRecoilLoadableProps<StateType> {
    state: RecoilState<StateType>;
    loadable: RecoilValueReadOnly<StateType> | RecoilState<StateType>;
    alwaysSet?: boolean;
}

export const useRecoilLoadable = <StateType>({
    state,
    loadable,
    alwaysSet,
}: IUseRecoilLoadableProps<StateType>) => {
    const [data, setData] = useRecoilState(state);
    const dataLoadable = useRecoilValueLoadable(loadable);

    React.useEffect(() => {
        if ((alwaysSet || !data) && dataLoadable.state === 'hasValue') {
            setData(dataLoadable.contents);
        }
    }, [dataLoadable, setData, data, alwaysSet]);

    if (data) {
        return {
            data,
            loading: false,
            error: undefined,
        };
    } else if (dataLoadable.state === 'hasValue') {
        return {
            data: dataLoadable.contents,
            loading: false,
            error: undefined,
        };
    } else if (dataLoadable.state === 'loading') {
        return {
            data: undefined,
            loading: true,
            error: undefined,
        };
    } else {
        return {
            data: undefined,
            loading: false,
            error: dataLoadable.errorOrThrow(),
        };
    }
};