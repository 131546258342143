import React from 'react';

import classNames from 'classnames';

import {useNavbar} from 'shared/hooks/useNavbar';

import {FullScreenModalClose} from './FullScreenModalClose';

import './styles.scss';

interface IFullScreenModalProps {
    className?: string;
    closeTo?: string;
    close?: boolean;
    size?: 'sm' | 'md' | 'lg';
    onClose?: () => void;
}

export const FullScreenModal = ({
    className,
    children,
    closeTo,
    close,
    size,
    onClose,
}: React.PropsWithChildren<IFullScreenModalProps>) => {
    useNavbar(false);

    const containerClassName = classNames('FullScreenModal__container', {
        [`FullScreenModal__container--${size}`]: size,
    });

    return (
        <div className={classNames(className, 'FullScreenModal')}>
            {close && (
                <FullScreenModalClose to={closeTo} onClick={onClose} />
            )}
            <div className={containerClassName}>
                {children}
            </div>
        </div>
    );
};

FullScreenModal.defaultProps = {
    close: true,
};
