import React from 'react';

import {authContext, useAuthProvider} from 'shared/hooks/useAuth';
import {useTenantInfo} from 'modules/tenant/hooks';

interface IAuthProviderProps {
    children?: React.ReactNode;
}

export function AuthProvider({children}: IAuthProviderProps) {
    const {tenantInfo} = useTenantInfo();
    const auth = useAuthProvider({
        tenantId: tenantInfo.id,
    });

    return (
        <authContext.Provider value={auth}>{children}</authContext.Provider>
    );
}
