import axios from 'axios';

import {IAssignmentProgress, IAssignmentProgressBatchResult, IAssignmentProgressKeys} from '../models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {getApiUrlForPath, getAxiosOptions} from 'shared/utils/api';

interface IReadAssignmentProgressProps {
    tenantId: number;
    userId: number;
    courseId: number;
}

export const readAssignmentProgress = async ({
    tenantId,
    userId,
    courseId,
}: IReadAssignmentProgressProps): Promise<IAssignmentProgress> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getApiUrlForPath(`/tenants/${tenantId}/assignments/${userId}/${courseId}/progress`),
        getAxiosOptions(accessToken),
    );
    return response.data as IAssignmentProgress;
};

export const readAssignmentProgressBatch = async (
    tenantId: number,
    assignmentProgressKeys: IAssignmentProgressKeys,
): Promise<IAssignmentProgressBatchResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getApiUrlForPath(`/tenants/${tenantId}/assignments/progress/batch`),
        assignmentProgressKeys,
        getAxiosOptions(accessToken),
    );
    return response.data;
};