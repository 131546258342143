import React from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'shared/hooks/useAuth';
import { useLoadingPage } from 'shared/hooks/useLoadingPage';
import { useNavbar } from 'shared/hooks/useNavbar';

export const Logout = () => {
    useNavbar(false);
    useLoadingPage(true);

    const {t} = useTranslation();
    const {signOut} = useAuth();

    React.useEffect(() => {
        (async () => {
            await signOut();
            window.location.pathname = '/';
            toast.success(t('auth:logout.successMessage', 'You have successfully signed out of your account.'));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <></>
    );
};
