import {selectorFamily} from 'recoil';

import {readResetPasswordTokenExists} from '../api/password';
import {IResetPasswordTokenPath} from '../models/password';

export const validateResetPasswordTokenSelector = selectorFamily<void, IResetPasswordTokenPath>({
    key: 'resetPassword:validateToken',
    get: (tokenPath) => async () => {
        return await readResetPasswordTokenExists(tokenPath);
    },
});
