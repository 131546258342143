import React from 'react';

import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {ICourse} from 'modules/course/models';
import {tenantIdSelector} from 'modules/tenant/state/tenant-info';
import {
    courseBatchLoadingSetSelector,
    courseBatchLookupSelector,
    courseBatchNotFoundSelector,
    courseBatchSetSelector,
    missingCourseIdSelector,
} from 'modules/course/state/course-lookup';
import {useBool} from 'shared/hooks/useBool';
import {readCourseBatch} from 'modules/course/api';

export const useCourseBatchLoadable = (courseIds: number[]) => {
    const tenantId = useRecoilValue(tenantIdSelector);
    const courses = useRecoilValue(courseBatchLookupSelector(courseIds));

    const [isLoading, setIsLoading] = useBool(true);
    const [loadedCourses, setLoadedCourses] = React.useState<ICourse[]>([]);

    // This hook is more complicated than usual because it needs to handle a number of use cases, such as a course not
    // found on server, versus a course not found in state as well as tracking which courses are in the process of
    // loading. Below is each recoil selector's role in this:

    // Role of the different selectors:
    // missingCourseIdSelector: returns which course IDs are missing from state that we need to load from the server.
    // courseBatchLoadingSetSelector: set which courses are currently loading, so we don't issue duplicate requests
    //  while some are in progress. missingCourseIdSelector respects this state.
    // courseBatchSetSelector: save courses to state once loaded.
    // courseBatchNotFoundSelector: track which courses were not found or failed to load, so we don't attempt to load
    //  them again. Otherwise, if there was a single courseId that didn't exist on the server it would cause a infinite
    //  loop. missingCourseIdSelector respects this.
    const missingCourseIds = useRecoilValue(missingCourseIdSelector(courseIds));
    const setCourseLoadingIds = useSetRecoilState(courseBatchLoadingSetSelector);
    const setCourseBatch = useSetRecoilState(courseBatchSetSelector);
    const [notFoundIds, setNotFoundIds] = useRecoilState(courseBatchNotFoundSelector(courseIds));

    React.useEffect(() => {
        if (loadedCourses) {
            setCourseBatch(loadedCourses);
        }
    }, [loadedCourses, setCourseBatch]);

    React.useEffect(() => {
        setCourseLoadingIds(missingCourseIds);
        if (missingCourseIds.length) {
            (async () => {
                const courseBatchResult = await readCourseBatch(tenantId, missingCourseIds);
                setLoadedCourses(courseBatchResult.courses);
                if (!!courseBatchResult.errors?.length) {
                    setNotFoundIds(courseBatchResult.errors.map(error => error.course_id));
                }
                setIsLoading(false);
            })();
        } else {
            setIsLoading(false);
        }
    }, [missingCourseIds, setLoadedCourses, setCourseLoadingIds, tenantId, setNotFoundIds, setIsLoading]);

    if (!isLoading && courses.length + notFoundIds.length === courseIds.length) {
        return {
            courses,
            loading: false,
            error: undefined,
        };
    } else {
        return {
            courses: undefined,
            loading: true,
            error: undefined,
        };
    }
};
