import React from 'react';

import {useTenantInfoMaybe} from 'modules/tenant/hooks';
import {Config} from 'shared/config';

export const useTitle = (title?: string, overrideBaseTitle?: boolean) => {
    const tenantInfo = useTenantInfoMaybe();

    const appName = React.useMemo(() => {
        if (tenantInfo?.name) {
            return tenantInfo.name;
        } else if (Config.appName) {
            return Config.appName;
        } else {
            return 'Cloudschool';
        }
    }, [tenantInfo?.name]);

    React.useEffect(() => {
        if (!title) {
            document.title = appName;
        } else {
            document.title = overrideBaseTitle ? title : `${title} - ${appName}`;
        }
    }, [appName, title, overrideBaseTitle]);
};
