import {useGuaranteedAuth} from 'shared/hooks/useAuth';
import {useTenantInfo} from 'modules/tenant/hooks';

import {NavbarSidemenuHeader} from './NavbarSidemenuHeader';
import {NavbarSidemenuNav} from './NavbarSidemenuNav';
import {NavbarSidemenuFooter} from './NavbarSidemenuFooter';

import './style.scss';

export const NavbarSidemenuContent = () => {
    const {tokenData} = useGuaranteedAuth();
    const {tenantInfo} = useTenantInfo();

    return tenantInfo ? (
        <>
            <NavbarSidemenuHeader tenant={tenantInfo} />
            <NavbarSidemenuNav tenantId={tenantInfo.id} tokenData={tokenData} />
            <NavbarSidemenuFooter />
        </>
    ) : (
        <></>
    );
};
