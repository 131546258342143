import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';

import { getAxiosErrorOrThrow } from 'shared/utils/error';
import { readProfileAsUser, updateProfile } from 'modules/marketing-profile/api';
import { useGuaranteedAuth } from 'shared/hooks/useAuth';
import { IUpdateMarketingProfile } from 'modules/marketing-profile/models/marketing-profile';
import { registerUser } from 'modules/user/api/user';
import { userInsertSelector } from 'modules/user/state/user-insert';

import { UserRegistrationInfo } from 'users/pages/UserRegistrationForm/UserRegistrationInfo';
import { UserRegistrationExtraInfo } from 'users/pages/UserRegistrationForm/UserRegistrationExtraInfo';

const userInfoDefaults: IUpdateMarketingProfile = {
    DOB: undefined,
    school: '',
    year: undefined,
    phone: undefined,
    gender: undefined,
    interests: [],
    accept_terms: false,
};

export const UserRegistrationForm = () => {
    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [registrationStep, setRegistrationStep] = React.useState<number>(1);
    const [formData, setFormData] = React.useState<IUpdateMarketingProfile>(userInfoDefaults);

    const {tokenData, tenantId} = useGuaranteedAuth();

    const [profileId, setProfileId] = React.useState<string | undefined>();

    const insertUser = useSetRecoilState(userInsertSelector);

    const onSubmit = (data: IUpdateMarketingProfile) => {
        if (!profileId || isSubmitting) {
            return;
        }
        if (!data.accept_terms) {
            toast.error(t(
                'userInfoForm.form.registerError',
                'You must accept the terms and conditions to use the platform',
            ));
        }
        setIsSubmitting(true);
        (async () => {
            try {
                await updateProfile(tenantId, profileId, {...formData, ...data});
                const updatedUser = await registerUser(tenantId, tokenData.id);
                insertUser(updatedUser);
            } catch (e) {
                toast.error(getAxiosErrorOrThrow(e));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    const onSetData = (newData: IUpdateMarketingProfile) => {
        setFormData({...formData, ...newData});
    };

    React.useEffect(() => {
        (async () => {
            const profile = await readProfileAsUser(tenantId, tokenData.id);
            setProfileId(profile.id);
        })();
    }, [tenantId, tokenData.id]);
    
    return (
        <Container fluid className="d-flex vh-100">
            <Row className="m-auto align-self-center w-100">
                <Col xs={12} md={6} lg={6} className="mx-auto">
                    <h1 style={{textAlign: 'center', marginTop: '20px', marginBottom: '20px'}}>
                        {t('userInfoForm.form.title', 'User Registration Form')}
                    </h1>
                    {registrationStep === 1 ? (
                        <UserRegistrationInfo
                            isSubmitting={isSubmitting}
                            formData={formData}
                            onSetData={onSetData}
                            onNextStep={() => setRegistrationStep((step) => ++step)}
                        />
                    ) : (
                        <UserRegistrationExtraInfo
                            isSubmitting={isSubmitting}
                            formData={formData}
                            onSetData={onSetData}
                            onPreviousStep={() => setRegistrationStep((step) => --step)}
                            onSubmit={onSubmit}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};