import {DefaultValue, selector} from 'recoil';

import {INotification} from 'modules/notification/models';
import {
    notificationInNotificationListSelector,
    notificationListInsertSelector,
    notificationListRemoveSelector,
} from './notification-list';
import {unreadNotificationCountRemoveSelector} from './unread-notification-count';
import {notificationLookupRemoveSelector, notificationLookupSelector} from './notification-lookup';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';

export const notificationInsertSelector = selector<INotification>({
    key: 'notificationInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        // update the unread count if the notification's "is_read" property has changed
        const notification = get(notificationInNotificationListSelector(newValue.id));
        if (notification && !notification.is_read && newValue.is_read) {
            set(unreadNotificationCountRemoveSelector, 1);
        }

        set(notificationListInsertSelector, newValue);
        set(notificationLookupSelector({tenantId: newValue.tenant_id, notificationId: newValue.id}), newValue);
    },
});

export const notificationRemoveSelector = selector<number>({
    key: 'notificationRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, notificationId) => {
        if (notificationId instanceof DefaultValue) {
            return;
        }

        // update the unread count if the deleted notification was unread
        const notification = get(notificationInNotificationListSelector(notificationId));
        if (notification && !notification.is_read) {
            set(unreadNotificationCountRemoveSelector, 1);
        }

        set(notificationListRemoveSelector, notificationId);
        set(notificationLookupRemoveSelector, notificationId);
    },
});
